import { axiosInstance } from "../../../core";
import { createRequestQuery } from "../../../core/helpers";
import { IResponse } from "../../../types";
import { ISurveyData } from "./survey.types";

class SurveyService {
  getSurveyAggregatedData(data: { [key: string]: any }) {
    return axiosInstance.get<IResponse<Array<ISurveyData>>>(
      `v1/feedback/aggregated?${createRequestQuery(data)}`
    );
  }
}

export const surveyService = new SurveyService();
