import { ILanguageButton } from "../../types";

const LanguageItem = ({
	id,
	image,
	label,
	activeLanguage,
	toggle,
	setMoreLanguagesIsOpen,
}: ILanguageButton) => {
	return (
		<button
			className={`${activeLanguage === id ? "active" : ""}`}
			onClick={() => {
				toggle(id);
				setMoreLanguagesIsOpen(false);
			}}
		>
			<img src={image} alt="" />
			<span>{label}</span>
		</button>
	);
};

export default LanguageItem;
