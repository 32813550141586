import { IAuthModel } from './auth.types'

const AUTH_LOCAL_STORAGE_KEY = 'hh-token-data'

export const getAuth = (): IAuthModel | null => {
  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)

  if (!lsValue) return null

  return JSON.parse(lsValue) as IAuthModel
}

export const setAuth = (auth: IAuthModel) => {
  localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, JSON.stringify(auth))
}

export const removeAuth = () => {
  localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
}