/* eslint-disable @typescript-eslint/no-explicit-any */
import clsx from 'clsx'
import { FieldInputProps, FormikErrors, FormikTouched } from 'formik'
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { IMediaData } from '../../../types'

type TProps = {
  data: Omit<IMediaData, 'type'>
  imgProps?: FieldInputProps<any>
  imgActionProps?: FieldInputProps<any>
  errors?: FormikErrors<Omit<IMediaData, 'type'>>
  touched?: FormikTouched<{ image: boolean, image_action_url: boolean }>
  handleChange: (e: any) => void
}

export const ImageUpload: FC<TProps> = (
  { data, imgProps, imgActionProps, errors, touched, handleChange }
) => {
  const intl = useIntl()
  const [imageValue, setImageValue] = useState<null | File>(null)
  const reader = new FileReader()

  reader.onload = (e) => {
    handleChange(e.target?.result)
  }

  useEffect(() => {
    if (imageValue) reader.readAsDataURL(imageValue)
  }, [imageValue])

  return (
    <div className='row'>
      <div className='col-12 col-md-4 d-flex flex-column align-items-start'>
        <label className='form-label fs-6 fw-bolder'>
          {intl.formatMessage({ id: 'DEFAULT_BANNERS.UPLOAD_AN_IMAGE' })}
        </label>

        <div
          className='mt-4 image-input image-input-outline'
          data-kt-image-input='true'
          style={{ backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})` }}
        >
          <label
            className='btn btn-sm btn-icon btn-circle btn-primary btn-shadow'
            data-kt-image-input-action='change'
          >
            <i className='fa fa-pen icon-sm' />
            <input
              type="file"
              accept='.png, .jpg, .jpeg'
              {...(imgProps || [])}
              onChange={e => setImageValue(e.target.files?.[0] || null)}
              value=''
            />
          </label>
          <div
            className='image-input-wrapper w-125px h-125px d-flex
            flex-column justify-content-center align-items-center w-200px h-200px'
            style={{ backgroundImage: data.image ? `url(${data.image})` : '' }}
          >
            {!data.image &&
              <>
                <i className="fa-solid fa-file fs-3x" />
                <div className='text-gray-700 fw-bold mt-3 text-center'>
                  {intl.formatMessage({ id: 'DEFAULT_BANNERS.NO_IMAGE_CHOSEN' })}
                </div>
              </>
            }
          </div>
        </div>
        <span className='form-text text-muted'>
          {intl.formatMessage({ id: 'DEFAULT_BANNERS.ALLOWED_FILE_TYPES' })}: png, jpg, jpeg.
        </span>

        {touched?.image && errors?.image && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert fst-medium'>{errors.image}</span>
            </div>
          </div>
        )}
      </div>

      <div className='col-12 mt-5 mt-md-0 col-md-8 d-flex flex-column align-items-start'>
        <label className='form-label fs-6 fw-bolder'>
          {intl.formatMessage({ id: 'DEFAULT_BANNERS.IMG_ACTION_URL' })}
        </label>
        <input
          type='text'
          className={clsx(
            'form-control form-control-lg form-control-solid mt-1 mt-md-4',
            { 'is-invalid': touched?.image_action_url && errors?.image_action_url },
            { 'is-valid': touched?.image_action_url && !errors?.image_action_url && data.image_action_url }
          )}
          placeholder={intl.formatMessage({ id: 'DEFAULT_BANNERS.IMG_ACTION_URL' })}
          {...(imgActionProps || [])}
        />

        {touched?.image_action_url && errors?.image_action_url && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{errors.image_action_url}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}