import { FC, useEffect, useState } from "react";
import { PageTitle } from "../../../_metronic/layout/core";
import { useToasts } from "../toasts/Toasts";
import { surveyService } from "./core/survey.service";
import { AppLoading } from "../../components/shared";
import { useIntl } from "react-intl";
import { useFilters } from "../../core/hooks";
import { LocationSelect } from "../default-banners/components";
import { useAuth } from "../auth";

import "survey-react/survey.css";
import "./survey.scss";
import { ISurveyData } from "./core/survey.types";
import SurveyDetail from "./components/SurveysDetail";

export const SurveyPage: FC = () => {
  const intl = useIntl();
  const { showToast } = useToasts();
  const { currentProfile } = useAuth();

  const [filters, setFilters] = useFilters(false, true);
  const [loading, setLoading] = useState(true);
  const [surveys, setSurvey] = useState([] as Array<ISurveyData>);

  useEffect(() => {
    initSurvey();
  }, [filters]);

  async function initSurvey() {
    setLoading(true);

    try {
      const { data } = await surveyService.getSurveyAggregatedData(filters);
      if (data.success) {
        setSurvey(data.data);
      }
    } catch {
      showToast();
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <PageTitle>
        {intl.formatMessage({ id: "MENU.MANAGE.FEEDBACK" })}
      </PageTitle>
      {loading ? (
        <AppLoading />
      ) : (
        <>
          {Object.keys(currentProfile?.locations || {}).length > 1 && (
            <div className="row">
              <div className="col-3 d-flex align-items-center">
                {intl.formatMessage({ id: "LABELS.SELECT_A_LOCATION" })}:
              </div>
              <div className="col-3">
                <LocationSelect
                  value={filters.location_id as number}
                  setValue={(v) => setFilters({ location_id: v })}
                />
              </div>
            </div>
          )}
          {surveys.map((survey, i) => {
            return <SurveyDetail key={survey.title} survey={survey} />;
          })}
        </>
      )}
    </>
  );
};
