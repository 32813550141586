import { FC } from 'react'
import { useIntl } from 'react-intl'
import { TooltipProps } from 'recharts'
import { KTCard, KTCardBody } from '../../../../../_metronic/helpers'
import { divideWithComma } from '../../../../core/helpers'

type TProps = TooltipProps<number, string> & {currency: string}

export const ReportTooltip: FC<TProps> = ({ active, payload, label, currency }) => {
  if (!active || !payload || !payload.length) return null

  const intl = useIntl()

  const round = (v: number): number => {
    return Math.round(v * 10) / 10
  }

  return (
    <KTCard className="border-gray-500" border>
      <KTCardBody>
        <span className='fw-bold fs-5'>{label}</span>
        <div className='text-primary'>
          <div>
            {intl.formatMessage({ id: 'GLOBAL.AMOUNT_PERCENT' })}:{' '}
            {round(payload[0].value || 0)}%
          </div>
          <div>
            {intl.formatMessage({ id: 'GLOBAL.AMOUNT' })}:{' '}
            {divideWithComma(payload[0].payload.rowFirstValue || 0)}{currency}
          </div>
        </div>
        {payload[1] && (
          <div className='text-success'>
            <div>
              {intl.formatMessage({ id: 'GLOBAL.PURCHASES_PERCENT' })}:{' '}
              {round(payload[1].value || 0)}%{' '}
            </div>
            <div>
              {intl.formatMessage({ id: 'GLOBAL.COUNT' })}:{' '}
              {payload[0].payload.rowSecondValue || 0}
            </div>
          </div>
        )}
      </KTCardBody>
    </KTCard>
  )
}