/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, useEffect, useState } from "react";
import { ISurveyAnswer } from "../core/survey.types";
import { Chart } from "primereact/chart";

type TProps = {
  answer: ISurveyAnswer;
};

const RankingSurveyAnswer: FC<TProps> = ({ answer }) => {
  const questionAnswers = answer.answers;
  const choices = answer.question.choices as Array<{
    value: string;
    text: string;
  }>;

  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    const lbls: any[] = [];
    const dt: any[] = [];
    const bgColor: any = [];

    choices.map((c) => {
      lbls.push(c.text);
      dt.push(questionAnswers[c.value]);
      bgColor.push("#" + Math.floor(Math.random() * 16777215).toString(16));
    });

    const data = {
      labels: [...lbls],
      datasets: [
        {
          data: [...dt],
          backgroundColor: [...bgColor],
          hoverBackgroundColor: [...bgColor],
        },
      ],
    };
    const options = {
      cutout: "80%",
    };

    setChartData(data);
    setChartOptions(options);
  }, []);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <h4 className="py-3">{answer.question.title} </h4>
          </div>
          <div className="col-sm-12 col-md-6">
            {choices.map((c) => {
              return (
                <>
                  <p>
                    {c.text} : {questionAnswers[c.value]}
                  </p>
                </>
              );
            })}
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="card flex justify-content-center">
              <Chart
                type="polarArea"
                data={chartData}
                options={chartOptions}
                style={{ maxWidth: 350 }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RankingSurveyAnswer;
