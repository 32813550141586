export const languageList = [
	{
		id: "javascript",
		label: "JavaScript",
		image: "https://api.iconify.design/logos:javascript.svg",
		code: `fetch("https://api.cusjor.me/pos/receipts/add", {
			method: method,
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data)
		})
		.then(response => response.json())
		.then(data => {
			console.log('Success:', data);
		})
		.catch(error => {
			console.error('Error:', error);
		});`,
	},
	{
		id: "python",
		label: "Python",
		image: "https://api.iconify.design/logos:python.svg",
		code: `import requests
 
		url = "https://api.cusjor.me/pos/receipts/add"
		method = "POST"
		headers = {
				'Content-Type': 'application/json',
		}
		data = {}
		 
		try:
				response = requests.request(method, url, headers=headers, json=data)
				response.raise_for_status()
				data = response.json()
				print('Success:', data)
		except requests.exceptions.RequestException as error:
				print('Error:', error)`,
	},
	{
		id: "java",
		label: "Java",
		image: "https://api.iconify.design/logos:java.svg",
		code: `import java.io.BufferedReader;
		import java.io.InputStreamReader;
		import java.io.OutputStream;
		import java.net.HttpURLConnection;
		import java.net.URL;
		
		public class Main {
				public static void main(String[] args) {
						try {
								URL url = new URL("https://api.cusjor.me/pos/receipts/add");
								HttpURLConnection connection = (HttpURLConnection) url.openConnection();
								connection.setRequestMethod(method);
								connection.setRequestProperty("Content-Type", "application/json");
								connection.setDoOutput(true);
		
								String data = JSON.stringify(data);
								OutputStream outputStream = connection.getOutputStream();
								outputStream.write(data.getBytes());
								outputStream.flush();
		
								int responseCode = connection.getResponseCode();
								if (responseCode == HttpURLConnection.HTTP_OK) {
										BufferedReader in = new BufferedReader(new InputStreamReader(connection.getInputStream()));
										String inputLine;
										StringBuilder response = new StringBuilder();
										while ((inputLine = in.readLine()) != null) {
												response.append(inputLine);
										}
										in.close();
										System.out.println("Success: " + response.toString());
								} else {
										System.out.println("Error: " + responseCode);
								}
						} catch (Exception e) {
								e.printStackTrace();
						}
				}
		}`,
	},
	{
		id: "php",
		label: "PHP",
		image: "https://api.iconify.design/vscode-icons:file-type-php.svg",
		code: `<?php
 
		$url = "https://api.cusjor.me/pos/receipts/add";
		$method = "POST";
		$data = array();
		 
		$options = array(
				'http' => array(
						'header'  => "Content-Type: application/json",
						'method'  => $method,
						'content' => json_encode($data),
				),
		);
		 
		$context  = stream_context_create($options);
		$response = file_get_contents($url, false, $context);
		 
		if ($response === false) {
				echo "Error: " . error_get_last()['message'];
		} else {
				$responseData = json_decode($response, true);
				echo "Success: " . print_r($responseData, true);
		}
		 
		?>`,
	},
	{
		id: "cpp",
		label: "C++",
		image: "https://api.iconify.design/vscode-icons:file-type-cpp3.svg",
		code: `#include <iostream>
		#include <curl/curl.h>
		
		// Define the URL and payload (data)
		const std::string url = "https://api.cusjor.me/pos/receipts/add";
		const std::string data = ""; // Form-urlencoded data
		
		int main() {
				CURL *curl;
				CURLcode res;
		
				// Initialize libcurl
				curl_global_init(CURL_GLOBAL_DEFAULT);
		
				// Create a curl handle
				curl = curl_easy_init();
				if(curl) {
						// Set the URL
						curl_easy_setopt(curl, CURLOPT_URL, url.c_str());
		
						// Set the request type to POST
						curl_easy_setopt(curl, CURLOPT_POST, 1L);
		
						// Set the POST data
						curl_easy_setopt(curl, CURLOPT_POSTFIELDS, data.c_str());
		
						// Perform the request
						res = curl_easy_perform(curl);
		
						// Check for errors
						if(res != CURLE_OK)
								fprintf(stderr, "curl_easy_perform() failed: %s\n",
												curl_easy_strerror(res));
		
						// Clean up and close the curl handle
						curl_easy_cleanup(curl);
				}
		
				// Clean up libcurl
				curl_global_cleanup();
		
				return 0;
		}`,
	},
	{
		id: "c",
		label: "C",
		image: "https://api.iconify.design/vscode-icons:file-type-c3.svg",
		code: `#include <stdio.h>
		#include <stdlib.h>
		#include <string.h>
		#include <curl/curl.h>
		
		// Function to handle the response
		size_t handle_response(void *contents, size_t size, size_t nmemb, char *response) {
				size_t total_size = size * nmemb;
				strcat(response, (char *)contents);
				return total_size;
		}
		
		int main() {
				CURL *curl;
				CURLcode res;
				char *url = "https://api.cusjor.me/pos/receipts/add";
				char *method = "POST";
				char *data = "";
				char response[4096] = "";
		
				// Initialize the CURL session
				curl = curl_easy_init();
				if (curl) {
						// Set the URL
						curl_easy_setopt(curl, CURLOPT_URL, url);
		
						// Set the request method
						curl_easy_setopt(curl, CURLOPT_CUSTOMREQUEST, method);
		
						// Set the headers
						struct curl_slist *headers = NULL;
						headers = curl_slist_append(headers, "Content-Type: application/json");
						curl_easy_setopt(curl, CURLOPT_HTTPHEADER, headers);
		
						// Set the request body
						curl_easy_setopt(curl, CURLOPT_POSTFIELDS, data);
		
						// Set the response handler
						curl_easy_setopt(curl, CURLOPT_WRITEFUNCTION, handle_response);
						curl_easy_setopt(curl, CURLOPT_WRITEDATA, response);
		
						// Perform the request
						res = curl_easy_perform(curl);
		
						// Check for errors
						if (res != CURLE_OK) {
								fprintf(stderr, "curl_easy_perform() failed: %s", curl_easy_strerror(res));
						} else {
								printf("Success: %s", response);
						}
		
						// Clean up
						curl_easy_cleanup(curl);
				}
		
				return 0;
		}`,
	},
	{
		id: "csharp",
		label: "C#",
		image: "https://api.iconify.design/vscode-icons:file-type-csharp2.svg",
		code: `using System;
		using System.Net.Http;
		using System.Threading.Tasks;
		
		public class Program
		{
				public static async Task Main()
				{
						var url = "https://api.cusjor.me/pos/receipts/add";
						var method = HttpMethod.Post;
						var data = new {};
		
						using (var client = new HttpClient())
						{
								var request = new HttpRequestMessage(method, url);
								request.Headers.Add("Content-Type", "application/json");
								request.Content = new StringContent(JsonConvert.SerializeObject(data));
		
								HttpResponseMessage response;
								try
								{
										response = await client.SendAsync(request);
								}
								catch (Exception ex)
								{
										Console.WriteLine($"Error: {ex.Message}");
										return;
								}
		
								if (response.IsSuccessStatusCode)
								{
										var responseContent = await response.Content.ReadAsStringAsync();
										Console.WriteLine($"Success: {responseContent}");
								}
								else
								{
										Console.WriteLine($"Error: {response.StatusCode}");
								}
						}
				}
		}`,
	},
	{
		id: "rust",
		label: "Rust",
		image: "https://api.iconify.design/skill-icons:rust.svg",
		code: `use reqwest::blocking::Client;

		fn main() {
				let url = "https://api.cusjor.me/pos/receipts/add";
				let method = "POST";
				let data = "";
		
				let client = Client::new();
				let response = client
						.post(url)
						.header("Content-Type", "application/json")
						.body(data)
						.send();
		
				match response {
						Ok(response) => {
								let data = response.json::<serde_json::Value>().unwrap();
								println!("Success: {:?}", data);
						}
						Err(error) => {
								eprintln!("Error: {:?}", error);
						}
				}
		}`,
	},
	{
		id: "ruby",
		label: "Ruby",
		image: "https://api.iconify.design/vscode-icons:file-type-ruby.svg",
		code: `require 'net/http'
		require 'json'
		
		uri = URI("https://api.cusjor.me/pos/receipts/add")
		http = Net::HTTP.new(uri.host, uri.port)
		http.use_ssl = true
		
		request = Net::HTTP::Post.new(uri.path)
		request['Content-Type'] = 'application/json'
		request.body = data.to_json
		
		response = http.request(request)
		
		if response.is_a?(Net::HTTPSuccess)
			data = JSON.parse(response.body)
			puts "Success: #{data}"
		else
			puts "Error: #{response.body}"
		end`,
	},
	{
		id: "swift",
		label: "Swift",
		image: "https://api.iconify.design/vscode-icons:file-type-swift.svg",
		code: `import Foundation

		let url = URL(string: "https://api.cusjor.me/pos/receipts/add")!
		var request = URLRequest(url: url)
		request.httpMethod = "POST"
		let data = "".data(using: .utf8) // Form-urlencoded data
		request.httpBody = data
		
		let task = URLSession.shared.dataTask(with: request) { data, response, error in
				guard let data = data, let httpResponse = response as? HTTPURLResponse, error == nil else {
						print("Error:", error?.localizedDescription ?? "Unknown error")
						return
				}
				
				if (200..<300).contains(httpResponse.statusCode) {
						// Request was successful
						if let responseString = String(data: data, encoding: .utf8) {
								print("Success:", responseString)
						} else {
								print("Success, but couldn't decode response.")
						}
				} else {
						// Request failed
						print("Error:", httpResponse.statusCode)
				}
		}
		
		task.resume()`,
	},
];
