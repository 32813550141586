import { useIntl } from "react-intl";
import * as Yup from "yup";

export const useValidationSchema = () => {
  const intl = useIntl();

  const newPasswordValidationSchema = Yup.string()
    .min(8, intl.formatMessage({ id: "VALIDATION.MINIMUM" }, { n: 8 }))
    .max(50, intl.formatMessage({ id: "VALIDATION.MAXIMUM" }, { n: 50 }))
    .matches(
      /[a-z]+/,
      intl.formatMessage(
        { id: "AUTH.VALIDATION.PASSWORD_SHOULD_CONTAIN" },
        { name: intl.formatMessage({ id: "AUTH.VALIDATION.LOWERCASE_LETTER" }) }
      )
    )
    .matches(
      /[A-Z]+/,
      intl.formatMessage(
        { id: "AUTH.VALIDATION.PASSWORD_SHOULD_CONTAIN" },
        { name: intl.formatMessage({ id: "AUTH.VALIDATION.UPPERCASE_LETTER" }) }
      )
    )
    .matches(
      /[1-9]+/,
      intl.formatMessage(
        { id: "AUTH.VALIDATION.PASSWORD_SHOULD_CONTAIN" },
        { name: intl.formatMessage({ id: "GLOBAL.NUMBER" }) }
      )
    )
    .matches(
      /[~`!#$%^&*+=@_\-[\]\\';,/{}|\\":<>?]/g,
      intl.formatMessage(
        { id: "AUTH.VALIDATION.PASSWORD_SHOULD_CONTAIN" },
        { name: intl.formatMessage({ id: "AUTH.VALIDATION.SPECIAL_SYMBOL" }) }
      )
    )
    .required(
      intl.formatMessage(
        { id: "VALIDATION.REQUIRED" },
        { name: intl.formatMessage({ id: "AUTH.NEW_PASSWORD" }) }
      )
    );

  const currentPasswordValidationSchema = Yup.string()
    .min(3, intl.formatMessage({ id: "VALIDATION.MINIMUM" }, { n: 8 }))
    .max(50, intl.formatMessage({ id: "VALIDATION.MAXIMUM" }, { n: 50 }))
    .required(
      intl.formatMessage(
        { id: "VALIDATION.REQUIRED" },
        { name: intl.formatMessage({ id: "AUTH.PASSWORD" }) }
      )
    );

  const confirmPasswordValidationSchema = Yup.string()
    .required(
      intl.formatMessage(
        { id: "VALIDATION.REQUIRED" },
        { name: intl.formatMessage({ id: "AUTH.PASSWORD_CONFIRMATION" }) }
      )
    )
    .when("new_password", {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("new_password")],
        intl.formatMessage({ id: "AUTH.CONFIRM_PASSWORD_INCORRECT" })
      ),
    })
    .when("password", {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        intl.formatMessage({ id: "AUTH.CONFIRM_PASSWORD_INCORRECT" })
      ),
    });

  const emailValidationSchema = Yup.string()
    .email(
      intl.formatMessage(
        { id: "VALIDATION.WRONG_FORMAT" },
        { name: intl.formatMessage({ id: "AUTH.EMAIL" }) }
      )
    )
    .required(
      intl.formatMessage(
        { id: "VALIDATION.REQUIRED" },
        { name: intl.formatMessage({ id: "AUTH.EMAIL" }) }
      )
    );

  return {
    newPasswordValidationSchema,
    currentPasswordValidationSchema,
    confirmPasswordValidationSchema,
    emailValidationSchema,
  };
};
