import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import { DashboardPage } from "../modules/dashboard";
import ChangePasswordPage from "../modules/auth/pages/ChangePasswordPage";
import { DefaultBannersPage } from "../modules/default-banners";
import { InsightsPage } from "../modules/insights";
import { ReceiptsListPage } from "../modules/receipts-list";
import { ApiTokensPage } from "../modules/api-tokens";
import { SurveyPage } from "../modules/survey";

const PrivateRoutes = () => {
	return (
		<Routes>
			<Route path="change-password" element={<ChangePasswordPage />} />

			<Route element={<MasterLayout />}>
				<Route path="auth/*" element={<Navigate to="/dashboard" />} />

				<Route path="dashboard" element={<DashboardPage />} />

				<Route path="/dashboard/*" element={<DashboardPage />} />
				<Route path="default-banners" element={<DefaultBannersPage />} />
				<Route path="insights/*" element={<InsightsPage />} />
				<Route path="receipts-list" element={<ReceiptsListPage />} />
				<Route path="api-tokens" element={<ApiTokensPage />} />
				<Route path="survey" element={<SurveyPage />} />

				<Route path="*" element={<Navigate to="/error/404" />} />
			</Route>
		</Routes>
	);
};

export { PrivateRoutes };
