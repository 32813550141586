import { FC, ReactElement, useMemo } from 'react'
import { useIntl } from 'react-intl'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts'
import { ISimpleChartDataItem } from '../../types'
import { usePercentage } from './core/hooks/use-percentage.hook'

type TProps = {
  data: ISimpleChartDataItem[]
  tooltip?: ReactElement
  firstValueName?: string
  secondValueName?: string
  formatToPercent?: boolean
  showLegend?: boolean
  className?: string
}

export const SimpleBarChart: FC<TProps> = ({
  data,
  tooltip,
  firstValueName,
  secondValueName,
  formatToPercent,
  className,
  showLegend = true
}) => {
  const intl = useIntl()
  const finalData = useMemo<ISimpleChartDataItem[]>(() => {
    if (!formatToPercent) return data

    return usePercentage(data)
  }, [data])

  const isEmpty = useMemo<boolean>(() => {
    return !finalData.length || finalData.every((d) => !d.firstValue && !d.secondValue)
  }, [finalData])

  return (
    <>
      {
        isEmpty
          ? <div className='fs-3x text-gray-500 fw-bold'>{intl.formatMessage({ id: 'GLOBAL.NO_DATA' })}</div>
          : (
            <div style={{ height: '280px' }} className={className}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  width={500}
                  height={300}
                  data={finalData}
                  margin={{
                    top: 5,
                    right: 10,
                    bottom: 5,
                    left: -20
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis unit={formatToPercent ? '%' : ''} />
                  <Tooltip content={tooltip} />
                  {showLegend && <Legend />}
                  <Bar name={firstValueName} dataKey="firstValue" fill="#009ef7" />
                  { data.some(d => d.secondValue) &&
                  <Bar name={secondValueName} dataKey="secondValue" fill="#50cd89" />
                  }
                </BarChart>
              </ResponsiveContainer>
            </div>
          )
      }
    </>
  )
}
