export enum EReceiptsListStatus {
  All = 'all',
  Sent = 'sent',
  Viewed = 'viewed',
  Unsent = 'unsent'
}

export enum ESortBy {
  ReceiptDate = 'ReceiptDate',
  InvoiceNo = 'InvoiceNo',
  TicketNumber = 'TicketNumber'
}

export interface IReceiptItem {
  id: string
  location_id: string
  location_name: string
  invoice_no: number
  ticket_number: string
  customer_mobile_no: string
  date_time: string
  status: string
  notes: string | null
  shortUrl: string
}

export interface IReceiptListStatistics {
  [EReceiptsListStatus.All]: number
  [EReceiptsListStatus.Sent]: number
  [EReceiptsListStatus.Viewed]: number
  [EReceiptsListStatus.Unsent]: number
}

export interface IPageData {
  limit: number
  offset: number
  page_count: number
  page_size: number
  total_count: number
}

export interface IReceiptsListData {
  statistics: IReceiptListStatistics
  items: IReceiptItem[]
  page: IPageData
}