import { FC } from 'react'
import { useIntl } from 'react-intl'

type TProps = {
  redirectUrl: string
  loading: boolean
  onLogin: () => void
}

export const AdminLoginModal: FC<TProps> = ({ redirectUrl, loading, onLogin }) => {
  const intl = useIntl()

  const handleRedirect = () => {
    window.location.replace(redirectUrl)
  }

  return (
    (
      <div className='modal fade' id='admin-login' aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2>
                {intl.formatMessage({ id: 'AUTH.ADMIN_ACCESS_DETECTED' })}
              </h2>
            </div>
            <div className='modal-body'>
              <div className='d-flex align-items-center justify-content-around'>
                <button
                  className='btn btn-sm btn-primary'
                  disabled={loading}
                  onClick={handleRedirect}
                  type='button'
                >
                  {intl.formatMessage({ id: 'AUTH.LOGIN_AS_ADMIN' })}
                </button>

                <button
                  className='btn btn-sm btn-primary'
                  disabled={loading}
                  onClick={onLogin}
                  type='button'
                >
                  {intl.formatMessage({ id: 'AUTH.LOGIN_AS_USER' })}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  )
}