export enum EMonth {
  Jan = 'Jan',
  Feb = 'Feb',
  Mar = 'Mar',
  Apr = 'Apr',
  May = 'May',
  Jun = 'Jun',
  Jul = 'Jul',
  Aug = 'Aug',
  Sep = 'Sep',
  Oct = 'Oct',
  Nov = 'Nov',
  Dec = 'Dec'
}

export enum EWeekDay {
  Mon = 'Mon',
  Tue = 'Tue',
  Wed = 'Wed',
  Thu = 'Thu',
  Fri = 'Fri',
  Sat = 'Sat',
  Sun = 'Sun'
}

export enum EDayPart {
  Mornings = 'Mornings',
  Noons = 'Noons',
  Evenings = 'Evenings',
  Nights = 'Nights'
}

export enum EMediaType {
  Image = 'Image',
  Video = 'Video',
  None = 'None'
}

export interface IResponse<T> {
  success: boolean
  data: T
}

export interface IFilters {
  location_id?: number | null
  month?: string | null
  year?: string | null
  dateRange?: [Date, Date]
}

export interface IMediaData {
  type: EMediaType
  image: string
  image_action_url: string
  video: string
}