import { ISimpleChartDataItem } from '../charts.types'

export const usePercentage = (data: ISimpleChartDataItem[]) => {
  const totals = data.reduce((acc, item) => {
    acc[0] += item.firstValue
    acc[1] += item.secondValue || 0

    return acc
  }, [0, 0])

  return data.map((item) => {
    item.rowFirstValue = item.firstValue
    item.firstValue = item.firstValue / totals[0] * 100

    if (item.secondValue !== undefined) {
      item.rowSecondValue = item.secondValue
      item.secondValue = item.secondValue / totals[1] * 100
    }

    return item
  })
}