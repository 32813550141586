import { FC } from 'react'
import { useIntl } from 'react-intl'
import { EMediaType, IMediaData } from '../../types'

type TProps = {
  header: IMediaData
  footer: IMediaData
}

export const Preview: FC<TProps> = ({ header, footer }) => {
  const intl = useIntl()

  const getImage = (data: IMediaData) => {
    const img = <img src={header.image} className='w-100' />

    if (!data.image_action_url) return img

    return (
      <a href={data.image_action_url} target="_blank" rel="noreferrer">
        {img}
      </a>
    )
  }

  const getIframe = (data: IMediaData) => {
    return (
      <iframe
        src={`https://www.youtube.com/embed/${data.video}?rel=0&controls=0&showinfo=0`}
        className='w-100 h-100'
        style={{ borderRadius: '.65rem' }}
      />
    )
  }

  return (
    <div className='border border-gray-700 mw-300px mx-auto py-5 bg-white'>
      <div className='d-flex justify-content-center pb-10 px-5'>
        {
          header.type === EMediaType.Video
            ? getIframe(header)
            : header.type === EMediaType.Image
              ? getImage(header)
              : <></>
        }
      </div>

      <h2 className='text-center fw-bolder'>
        Cusjor
        {intl.formatMessage({ id: 'GLOBAL.RECEIPT' })}
      </h2>

      <div className='d-flex flex-column align-items-center px-5 fw-bolder fs-2 mt-10'>
        <div className='d-flex align-items-center justify-content-center w-100'>
          <span>
            {intl.formatMessage({ id: 'RECEIPT.INVOICE' })} #83923<br />
          </span>
        </div>

        <div>
          {intl.formatMessage({ id: 'RECEIPT.TOKEN_NO' })}: 125
        </div>
      </div>

      <div className='row p-1 mx-0 border-top border-bottom border-gray-700'>
        <div className='col-3'>
          {intl.formatMessage({ id: 'RECEIPT.NUMBER' })}
        </div>
        <div className='col-5'>
          {intl.formatMessage({ id: 'RECEIPT.NAME' })}
        </div>
        <div className='col-2'>
          {intl.formatMessage({ id: 'RECEIPT.QTY' })}
        </div>
        <div className='col-2'>
          {intl.formatMessage({ id: 'RECEIPT.AMT' })}
        </div>
      </div>

      <div className='row p-1 mx-0 border-bottom border-gray-700'>
        <div className='col-3'>
          1
        </div>
        <div className='col-5'>
          {intl.formatMessage({ id: 'RECEIPT.NAME_EXMPL' })}
        </div>
        <div className='col-2'>
          1
        </div>
        <div className='col-2'>
          9.00
        </div>
      </div>

      <div className='d-flex flex-column border-bottom border-gray-700'>
        <div className='row p-1 mx-0'>
          <div className='col-10'>
            <strong>
              {intl.formatMessage({ id: 'RECEIPT.TOTAL' })}
            </strong>
          </div>

          <div className='col-2'>
            <strong>13.00</strong>
          </div>
        </div>

        <div className='row p-1 mx-0'>
          <div className='col-10'>
            {intl.formatMessage({ id: 'RECEIPT.CASH' })}
          </div>
          <div className='col-2'>13.00</div>
        </div>

        <div className='row p-1 mx-0'>
          <div className='col-10'>
            {intl.formatMessage({ id: 'RECEIPT.CHANGE' })}
          </div>
          <div className='col-2'>
            0.00
          </div>
        </div>
      </div>

      <div className='d-flex flex-column border-bottom border-gray-700'>
        <div className='row p-1 mx-0'>
          <div className='col-7'>
            {intl.formatMessage({ id: 'RECEIPT.TOTAL_NO_OF_ITEMS' })}
          </div>
          <div className='col-5'>
            <strong>
              2
            </strong>
          </div>
        </div>
        <div className='row p-1 mx-0'>
          <div className='col-6'>
            02/03/2023
          </div>
          <div className='col-6'>
            10:45:20
          </div>

          <div className='col-12'>
            {intl.formatMessage({ id: 'RECEIPT.TABLE_NO' })}: 2
          </div>
          <div className='col-12'>
            {intl.formatMessage({ id: 'RECEIPT.CASHIER' })}: {intl.formatMessage({ id: 'RECEIPT.CASHIER' })}-1
          </div>
        </div>
      </div>

      <div className='text-center mt-2'>
        {intl.formatMessage({ id: 'RECEIPT.THANK_YOU' })}
      </div>

      <div className='d-flex justify-content-center pt-10 px-5'>
        {
          footer.type === EMediaType.Video
            ? getIframe(footer)
            : footer.type === EMediaType.Image
              ? getImage(footer)
              : <></>
        }
      </div>
    </div>
  )
}