import clsx from 'clsx'
import { FC, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { EReceiptsListStatus } from '../core/receipts-list.types'

type TProps = {
  type: EReceiptsListStatus
  value: number
  total: number
  onClick: () => void
}

const bgByType = {
  [EReceiptsListStatus.All]: 'bg-primary',
  [EReceiptsListStatus.Sent]: 'bg-success',
  [EReceiptsListStatus.Viewed]: 'bg-danger',
  [EReceiptsListStatus.Unsent]: 'bg-warning'
}

const titleByType = {
  [EReceiptsListStatus.All]: 'ALL_RECEIPTS',
  [EReceiptsListStatus.Sent]: 'SENT_RECEIPTS',
  [EReceiptsListStatus.Viewed]: 'VIEWED_RECEIPTS',
  [EReceiptsListStatus.Unsent]: 'UNSENT_RECEIPTS'
}

export const StatusOverall: FC<TProps> = ({ type, value, total, onClick }) => {
  const intl = useIntl()
  const percent = useMemo<number>(() => {
    if (!value) return 0

    return Math.round((value / total * 100) * 10) / 10
  }, [value, total])

  return (
    <div className='d-flex flex-column'>
      <div className={clsx('p-5 d-flex flex-column rounded-top', bgByType[type])}>
        <span className='fs-5 fw-bold text-white uppercase mb-0 text-truncate'>
          {intl.formatMessage({ id: `GLOBAL.${titleByType[type]}` })}
        </span>

        <div className='d-flex align-items-center fs-1 text-white'>
          <strong>
            {value}
          </strong>

          {type !== EReceiptsListStatus.All && <span className='ms-1 fs-2 fw-bold'>({percent}%)</span>}
        </div>
      </div>

      <button
        className={clsx(
          'btn d-flex align-items-center justify-content-between text-white opacity-75 rounded-bottom',
          bgByType[type]
        )}
        style={{ borderRadius: '0' }}
        onClick={onClick}
        disabled={!value}
      >
        {intl.formatMessage({ id: 'RECEIPTS_LIST.SHOW_RECEIPTS' })}
        <i className="fa-solid fa-arrow-right text-white" />
      </button>
    </div>
  )
}