import { createRoot } from "react-dom/client";
import { Chart, registerables } from "chart.js";

import { MetronicI18nProvider } from "./_metronic/i18n/Metronici18n";

import "./_metronic/assets/sass/plugins.scss";
import "./_metronic/assets/sass/style.scss";
import "./_metronic/assets/sass/style.react.scss";

import { AppRoutes } from "./app/routing/AppRoutes";
import { AuthProvider } from "./app/modules/auth";
import { ThemeModeProvider } from "./_metronic/partials/layout/theme-mode/ThemeModeProvider";
import { ToastsProvider } from "./app/modules/toasts/Toasts";
import { I18nProvider } from "./_metronic/i18n/i18nProvider";

Chart.register(...registerables);

const container = document.getElementById("root");
if (container) {
	createRoot(container).render(
		<MetronicI18nProvider>
			<ThemeModeProvider>
				<AuthProvider>
					<I18nProvider>
						<ToastsProvider>
							<AppRoutes />
						</ToastsProvider>
					</I18nProvider>
				</AuthProvider>
			</ThemeModeProvider>
		</MetronicI18nProvider>
	);
}
