export const createRequestQuery = (data: { [key: string]: any }): string => {
	return Object.entries(data)
		.filter(([_, value]) => value !== null && value !== undefined)
		.reduce((acc, [key, value]) => {
			if (key === "dateRange") {
				const startDate = value[0].toISOString().split("T")[0];
				const endDate = value[1].toISOString().split("T")[0];
				return (acc += `start_date=${startDate}&end_date=${endDate}&`);
			}

			return (acc += `${key}=${value}&`);
		}, "");
};

export const divideWithComma = (number: number): string => {
	return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const currencyParser = (price: number, currency: string) => {
	return new Intl.NumberFormat("en-US", {
		style: "currency",
		currency,
		maximumFractionDigits: 2,
		minimumFractionDigits: 0,
	}).format(price);
};
