import clsx from 'clsx'
import { FC, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { KTCard, KTCardBody } from '../../../../_metronic/helpers'
import { AppLoading } from '../../../components/shared'
import { ESortBy, IPageData, IReceiptItem } from '../core/receipts-list.types'

type TProps = {
  items: IReceiptItem[]
  sortOrder: 1 | -1
  sortBy: ESortBy | null
  pageData: IPageData
  loading: boolean
  changeSort: (s: ESortBy) => void
  toNextPage: () => void
  toPrevPage: () => void
  changeCountPerPage: (n: number) => void
}

const rowsPerPageOptions = [5, 10, 15, 20, 25, 30, 50]

export const ReceiptsTable: FC<TProps> = ({
  items,
  pageData,
  loading,
  sortBy,
  sortOrder,
  changeSort,
  toNextPage,
  toPrevPage,
  changeCountPerPage
}) => {
  const intl = useIntl()
  const formatDate = (dateString: string) => {
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    const hour = String(date.getHours()).padStart(2, '0')
    const minute = String(date.getMinutes()).padStart(2, '0')
    const second = String(date.getSeconds()).padStart(2, '0')
    return `${year}-${month}-${day} ${hour}:${minute}:${second}`
  }

  const displayedItemsLastIdx = useMemo(() => {
    const output = pageData.page_size + pageData.offset

    if (output > pageData.total_count) return pageData.total_count

    return output

  }, [pageData])

  const sortButton = <button className='btn btn-light btn-icon w-25px h-25px rounded-circle ms-2'>
    <i
      className={clsx('fa-solid',
        sortOrder === 1 ? 'fa-arrow-up' : 'fa-arrow-down'
      )}
    />
  </button>

  return (
    <KTCard>
      <KTCardBody>
        <h3 className='card-title'>
          {intl.formatMessage({ id: 'MENU.RECEIPTS_LIST' })}
        </h3>

        {
          loading
            ? <AppLoading height={300} />
            : items.length
              ? (
                <>
                  <div className='table-responsive'>
                    <table className='table table-striped mb-0' style={{ minWidth: '850px' }}>
                      <thead>
                        <tr className='fw-bold fs-5' style={{ height: '45px', verticalAlign: 'middle' }}>
                          <th scope='col'>
                            {intl.formatMessage({ id: 'RECEIPTS_LIST.CUSTOMER_MOBILE' })}
                          </th>
                          <th scope='col'>
                            {intl.formatMessage({ id: 'GLOBAL.URL' })}
                          </th>
                          <th
                            scope='col'
                            onClick={() => changeSort(ESortBy.ReceiptDate)}
                            style={{ cursor: 'pointer', userSelect: 'none' }}
                            className={clsx(sortBy === ESortBy.ReceiptDate && 'text-primary')}
                          >
                            {intl.formatMessage({ id: 'RECEIPTS_LIST.RECEIPT_DATE' })}

                            {sortBy === ESortBy.ReceiptDate && sortButton}
                          </th>
                          <th
                            scope='col'
                            onClick={() => changeSort(ESortBy.InvoiceNo)}
                            style={{ cursor: 'pointer', userSelect: 'none' }}
                            className={clsx(sortBy === ESortBy.InvoiceNo && 'text-primary')}
                          >
                            {intl.formatMessage({ id: 'RECEIPTS_LIST.INVOICE_NO' })}

                            {sortBy === ESortBy.InvoiceNo && sortButton}
                          </th>
                          <th scope='col'>
                            {intl.formatMessage({ id: 'RECEIPTS_LIST.TICKET_NUMBER' })}
                          </th>
                          <th scope='col'>
                            {intl.formatMessage({ id: 'GLOBAL.LOCATION' })}
                          </th>
                          <th scope='col'>
                            {intl.formatMessage({ id: 'GLOBAL.STATUS' })}
                          </th>
                          <th scope='col'>
                            {intl.formatMessage({ id: 'RECEIPTS_LIST.NOTES' })}

                          </th>
                          {/* <th scope='col'>Views</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {items.map(item => (
                          <tr key={item.id}>
                            <td>{item.customer_mobile_no}</td>
                            <td className='text-truncate'>
                              <a
                                className='text-truncate'
                                target='_blank'
                                href={item.shortUrl}
                                rel='noreferrer'
                              >
                                {item.shortUrl}
                              </a>
                            </td>
                            <td>{formatDate(item.date_time)}</td>
                            <td>{item.invoice_no}</td>
                            <td>{item.ticket_number}</td>
                            <td>{item.location_name}</td>
                            <td>{intl.formatMessage({ id: `RECEIPTS_LIST.STATUS.${item.status}` })}</td>
                            <td>{item.notes || '-'}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div
                    className='d-flex flex-column flex-sm-row align-items-sm-center justify-content-between mt-7'
                  >
                    <div className='d-flex align-items-center'>
                      <label className='form-label mb-0 me-3'>
                        {intl.formatMessage({ id: 'GLOBAL.ROWS_PER_PAGE' })}:
                      </label>
                      <select
                        name='count'
                        className='form-select form-select-sm w-100px'
                        value={pageData.page_size}
                        onChange={e => changeCountPerPage(parseInt(e.target.value) || pageData.page_size)}
                      >
                        {
                          rowsPerPageOptions.map(v => (
                            <option value={v} key={v}>
                              {v}
                            </option>
                          ))
                        }
                      </select>
                    </div>

                    <div className='d-flex align-items-center mt-3 mt-sm-0'>
                      <span className='fw-bold'>
                        {pageData.offset + 1} - {displayedItemsLastIdx} Of {pageData.total_count}
                      </span>

                      <div className='d-flex justify-items-center ms-2' dir='ltr'>
                        <button
                          className='btn btn-light btn-icon rounded-circle ms-3'
                          disabled={pageData.offset === 0}
                          onClick={toPrevPage}
                        >
                          <i className='fa-solid fa-arrow-left' />
                        </button>
                        <button
                          className='btn btn-light btn-icon rounded-circle ms-3'
                          disabled={pageData.offset + pageData.page_size >= pageData.total_count}
                          onClick={toNextPage}
                        >
                          <i className='fa-solid fa-arrow-right' />
                        </button>
                      </div>
                    </div>
                  </div>
                </> )
              : <div className='fs-2x text-gray-500 fw-bold'>
                {intl.formatMessage({ id: 'GLOBAL.NO_RESULTS_IN_PERIOD' })}
              </div>
        }

      </KTCardBody>
    </KTCard>
  )
}