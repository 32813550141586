import { AxiosResponse } from 'axios'
import { axiosInstance } from '../../../core'
import { createRequestQuery } from '../../../core/helpers'
import { IFilters, IResponse } from '../../../types'
import {
  ICustomersInsights,
  ISalesInsights,
  ITopProductsInsights
} from './insights.types'

interface IInsightsService {
  getSales: (f: IFilters) => Promise<AxiosResponse<IResponse<ISalesInsights>>>
  getCustomers: (f: IFilters) => Promise<AxiosResponse<IResponse<ICustomersInsights>>>
  getTopProducts: (f: IFilters) => Promise<AxiosResponse<IResponse<ITopProductsInsights>>>
}

class InsightsService implements IInsightsService {
  getSales(f: IFilters): Promise<AxiosResponse<IResponse<ISalesInsights>>> {
    return axiosInstance.get<IResponse<ISalesInsights>>(`/v1/insights/sales?${createRequestQuery(f)}`)
  }

  getCustomers(f: IFilters): Promise<AxiosResponse<IResponse<ICustomersInsights>>> {
    return axiosInstance.get<IResponse<ICustomersInsights>>(`/v1/insights/customers?${createRequestQuery(f)}`)
  }

  getTopProducts(f: IFilters): Promise<AxiosResponse<IResponse<ITopProductsInsights>>> {
    return axiosInstance.get<IResponse<ITopProductsInsights>>(`/v1/insights/top?${createRequestQuery(f)}`)
  }
}

export const insightsService = new InsightsService()