import { FC, useEffect } from "react";
import { useIntl } from "react-intl";

type TProps = {
	value: string | null;
	setValue: (value: string | null) => void;
	availableYears: string[];
};

export const YearSelect: FC<TProps> = ({ value, setValue, availableYears }) => {
	const intl = useIntl();

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		$("#year-select").select2({
			language: {
				noResults: () => intl.formatMessage({ id: "GLOBAL.NO_RESULTS" }),
			},
		});

		$("#year-select").on("select2:select", e => {
			const { value } = e.target as HTMLSelectElement;
			setValue(value);
		});
	}, []);

	return (
		<select
			name="year"
			id="year-select"
			aria-label={intl.formatMessage({ id: "LABELS.SELECT_A_YEAR" })}
			className="form-select form-select-sm w-200px"
			data-placeholder={intl.formatMessage({ id: "LABELS.SELECT_A_YEAR" })}
			defaultValue={value?.toString()}
		>
			{availableYears.map(year => {
				return (
					<option key={year} value={year}>
						{year}
					</option>
				);
			})}
		</select>
	);
};
