import { FC, useMemo } from 'react'
import { ActiveShapePieChart } from '../../../components/charts'
import { KTCard, KTCardBody } from '../../../../_metronic/helpers'
import { ILocation } from '../core/dashboard.types'
import { IPieChartDataItem } from '../../../types'
import { useIntl } from 'react-intl'

type TProps = {
  location: ILocation
}

export const ChartWithData: FC<TProps> = ({ location }) => {
  const intl = useIntl()

  const dataForChart = useMemo<IPieChartDataItem[]>(() => {
    return [
      { name: 'Generated', value: location.count, fill: '#009ef7' },
      { name: 'Viewed', value: location.viewed, fill: '#50cd89' }
    ]
  }, [location])

  const labels = useMemo<{ value: number, title: string, color: string }[]>(() => {
    return [
      { value: location.count, title: intl.formatMessage({ id: 'DASHBOARD.GENERATED_RECEIPTS' }), color: 'text-primary' },
      { value: location.viewed, title: intl.formatMessage({ id: 'GLOBAL.VIEWED_RECEIPTS' }), color: 'text-success' },
      { value: location.totalItems, title: intl.formatMessage({ id: 'DASHBOARD.TOTAL_ITEMS' }), color: 'text-warning-active' },
      { value: location.avgPerReceipt, title: intl.formatMessage({ id: 'DASHBOARD.AVG_ITEMS' }), color: 'text-warning-active' }
    ]
  }, [location])

  return (
    <KTCard>
      <KTCardBody className='d-flex flex-column align-items-center justify-content-center'>
        <h3 className='card-title'>
          {location.name}
        </h3>

        <div className='w-100 h-300px'>
          <ActiveShapePieChart data={dataForChart} />
        </div>

        <div className='row'>
          { labels.map(({ title, value, color }) => (
            <div key={title} className='col-6 d-flex flex-column align-items-center py-2'>
              <span className={`fs-2x ${color}`}>{value}</span>
              <em>
                {title}
              </em>
            </div>
          )) }
        </div>
      </KTCardBody>
    </KTCard>
  )
}