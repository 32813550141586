import { ThemeModeSwitcher } from '../../../partials'
import { Languages } from '../../../partials/layout/header-menus/Languages'
import { LanguageSwitcher } from '../../../partials/layout/language/LanguageSwitcher'

const Topbar = () => {
  return (
    <div className='d-flex align-items-center flex-shrink-0'>
      {/* begin::Theme mode */}
      <div className={'d-flex align-items-center ms-3 ms-xl-4'}>
        <ThemeModeSwitcher toggleBtnClass='btn-color-gray-700 btn-active-color-primary btn-outline w-40px h-40px' />
      </div>
      {/* end::Theme mode */}

      <div className='d=flex align-items-center ms-3 ms-xl-4'>
        <LanguageSwitcher toggleBtnClass='btn-color-gray-700 btn-active-color-primary btn-outline w-40px h-40px' />
      </div>
    </div>
  )
}

export { Topbar }
