import { AxiosResponse } from 'axios'
import { axiosInstance } from '../../../core'
import { createRequestQuery } from '../../../core/helpers'
import { IFilters, IResponse } from '../../../types'
import { EReceiptsListStatus, IReceiptsListData } from './receipts-list.types'

type TGetReceiptsPayload = IFilters & {
  page: number
  per_page: number
  filter: EReceiptsListStatus
}

interface IReceiptsListService {
  getReceipts: (p: TGetReceiptsPayload) => Promise<AxiosResponse<IResponse<IReceiptsListData>>>
}

class ReceiptsListService implements IReceiptsListService {
  getReceipts (p: TGetReceiptsPayload): Promise<AxiosResponse<IResponse<IReceiptsListData>>> {
    return axiosInstance.get<IResponse<IReceiptsListData>>(`/v1/receipts?${createRequestQuery(p)}`)
  }
}

export const receiptsListService = new ReceiptsListService()