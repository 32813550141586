import clsx from "clsx";
import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { PasswordMeterComponent } from "../../../../_metronic/assets/ts/components";
import { AuthLayout } from "../AuthPage";
import { authService } from "../core/auth.service";
import { IChangePasswordData } from "../core/auth.types";
import { useValidationSchema } from "../core/use-validation-schema.hook";
import { toast } from "react-toastify";

const ChangePasswordPage: FC = () => {
  const intl = useIntl();

  const navigate = useNavigate();
  const {
    currentPasswordValidationSchema,
    newPasswordValidationSchema,
    confirmPasswordValidationSchema,
  } = useValidationSchema();

  const [loading, setLoading] = useState<boolean>(false);
  const [showCurrPassword, setShowCurrPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);

  const currPasswordIcon = showCurrPassword ? `fa fa-eye` : `fa fa-eye-slash`;
  const newPasswordIcon = showNewPassword ? `fa fa-eye` : `fa fa-eye-slash`;
  const confPasswordIcon = showConfPassword ? `fa fa-eye` : `fa fa-eye-slash`;

  const initialValues: IChangePasswordData = {
    current_password: "",
    new_password: "",
    confirmPassword: "",
  };

  const changePasswordSchema = Yup.object().shape({
    current_password: currentPasswordValidationSchema,
    new_password: newPasswordValidationSchema,
    confirmPassword: confirmPasswordValidationSchema,
  });

  const formik = useFormik({
    initialValues,
    validationSchema: changePasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting, setErrors }) => {
      setLoading(true);
      setSubmitting(true);

      try {
        const resp = (await authService.changePassword(values)).data as any;
        if (!resp.success) {
          if (resp.errors.current_password) {
            setErrors({ current_password: resp.errors.current_password });
          }
          if (resp.errors.new_password) {
            setErrors({ new_password: resp.errors.new_password });
          }
          return;
        }

        toast(intl.formatMessage({ id: "AUTH.PASSWORD_CHANGE_SUCCESS" }));
        setTimeout(() => {
          navigate("/dashboard");
        }, 5000);
      } catch {
        setStatus(intl.formatMessage({ id: "GLOBAL.SOMETHING_WENT_WRONG" }));
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    PasswordMeterComponent.bootstrap();
  }, []);

  return (
    <AuthLayout>
      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        noValidate
        onSubmit={formik.handleSubmit}
      >
        <div className="mb-10 text-center">
          <h2 className="text-dark mb-3">
            {intl.formatMessage({ id: "AUTH.CHANGE_PASSWORD" })}
          </h2>
        </div>
        {formik.status && (
          <div className="mb-xl-15 alert alert-danger">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}

        <div className="fv-row mb-10 position-relative">
          <label className="form-label fw-bolder text-dark fs-6 is-required">
            {intl.formatMessage({ id: "AUTH.CURRENT_PASSWORD" })}
          </label>
          <input
            type={showCurrPassword ? "text" : "password"}
            placeholder={intl.formatMessage({ id: "AUTH.CURRENT_PASSWORD" })}
            autoComplete="off"
            {...formik.getFieldProps("current_password")}
            className={clsx(
              "form-control form-control-lg form-control-solid",
              {
                "is-invalid-0":
                  formik.touched.current_password &&
                  formik.errors.current_password,
              },
              {
                "is-valid-0":
                  formik.touched.current_password &&
                  !formik.errors.current_password,
              }
            )}
          />
          <span
            className={"toggle-password"}
            onClick={() => setShowCurrPassword(!showCurrPassword)}
          >
            <i className={currPasswordIcon} />
          </span>
          {formik.touched.current_password &&
            formik.errors.current_password && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.current_password}</span>
                </div>
              </div>
            )}
        </div>
        <div
          className="fv-row mb-10 position-relative"
          data-kt-password-meter="true"
        >
          <div className="mb-1">
            <label className="form-label fw-bolder text-dark fs-6 is-required">
              {intl.formatMessage({ id: "AUTH.NEW_PASSWORD" })}
            </label>
            <div className="position-relative mb-3">
              <input
                type={showNewPassword ? "text" : "password"}
                placeholder={intl.formatMessage({ id: "AUTH.NEW_PASSWORD" })}
                autoComplete="off"
                {...formik.getFieldProps("new_password")}
                className={clsx(
                  "form-control form-control-lg form-control-solid",
                  {
                    "is-invalid-0":
                      formik.touched.new_password && formik.errors.new_password,
                  },
                  {
                    "is-valid-0":
                      formik.touched.new_password &&
                      !formik.errors.new_password,
                  }
                )}
              />
              <span
                className={"toggle-password"}
                style={{ top: 14 }}
                onClick={() => setShowNewPassword(!showNewPassword)}
              >
                <i className={newPasswordIcon} />
              </span>

              {formik.touched.new_password && formik.errors.new_password && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.new_password}</span>
                  </div>
                </div>
              )}
            </div>
            <div
              className="d-flex align-items-center mb-3"
              data-kt-password-meter-control="highlight"
            >
              <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2" />
              <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2" />
              <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2" />
              <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px" />
            </div>
          </div>
          <div className="text-muted">
            {intl.formatMessage({ id: "AUTH.NEW_PASSWORD_HINT" })}
          </div>
        </div>
        <div className="fv-row mb-10 position-relative">
          <label className="form-label fw-bolder text-dark fs-6 is-required">
            {intl.formatMessage({ id: "AUTH.CONFIRM_NEW_PASSWORD" })}
          </label>
          <input
            type={showConfPassword ? "text" : "password"}
            placeholder={intl.formatMessage({
              id: "AUTH.CONFIRM_NEW_PASSWORD",
            })}
            autoComplete="off"
            {...formik.getFieldProps("confirmPassword")}
            className={clsx(
              "form-control form-control-lg form-control-solid",
              {
                "is-invalid-0":
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword,
              },
              {
                "is-valid-0":
                  formik.touched.confirmPassword &&
                  !formik.errors.confirmPassword,
              }
            )}
          />
          <span
            className={"toggle-password"}
            onClick={() => setShowConfPassword(!showConfPassword)}
          >
            <i className={confPasswordIcon} />
          </span>

          {formik.touched.confirmPassword && formik.errors.confirmPassword && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.confirmPassword}</span>
              </div>
            </div>
          )}
        </div>
        <div className="d-flex justify-content-between">
          <button
            type="button"
            className="btn btn-lg btn-secondary mb-5"
            onClick={() => navigate("/dashboard")}
          >
            {intl.formatMessage({ id: "BUTTONS.CANCEL" })}
          </button>

          <button
            type="submit"
            className="btn btn-lg btn-primary mb-5"
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && (
              <span className="indicator-label">
                {intl.formatMessage({ id: "BUTTONS.SUBMIT" })}
              </span>
            )}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                {intl.formatMessage({ id: "GLOBAL.PLEASE_WAIT" })}{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2" />
              </span>
            )}
          </button>
        </div>
      </form>
    </AuthLayout>
  );
};

export default ChangePasswordPage;
