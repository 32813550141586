import { AxiosResponse } from 'axios'
import { axiosInstance } from '../../../core'
import { createRequestQuery } from '../../../core/helpers'
import { IFilters, IResponse } from '../../../types'
import { IDashboardData } from './dashboard.types'

interface IDashboardService {
  getData: (f: IFilters) => Promise<AxiosResponse<IResponse<IDashboardData>>>
}

class DashboardService implements IDashboardService {
  getData(f: IFilters) {
    return axiosInstance.get<IResponse<IDashboardData>>(`/v1/dashboard?${createRequestQuery(f)}`)
  }
}

export const dashboardService = new DashboardService()