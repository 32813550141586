import clsx from 'clsx'
import { FC } from 'react'

type TProps = {
  fullScreen?: boolean
  className?: string
  height?: number
}

export const AppLoading: FC<TProps> = ({ className, fullScreen, height = 500 }) => {
  return (
    <div
      className={clsx(
        'd-flex flex-column align-items-center justify-content-center',
        fullScreen ? 'vw-100 vh-100' : `h-${height}px`,
        className
      )}
    >

      <span className='spinner-border w-50px h-50px text-primary' />

      <strong className='text-gray-700 mt-5 fs-2x'>Cusjor</strong>
    </div>
  )
}