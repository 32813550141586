import { FC, useEffect, useState } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { AppLoading } from '../../../../components/shared'
import { Filters } from '../../../../components/shared'
import { insightsService } from '../../core/insights.service'
import { ICustomersInsights } from '../../core/insights.types'
import { useFilters } from '../../../../core/hooks'
import { SalesPerProductsQty } from './SalesPerProductsQty'
import { RepeatVsOneTime } from './RepeatVsOneTime'
// import { SalesKPI } from './SalesKPI'
import { SalesPerPaymentType } from './SalesPerPaymentType'
import { VisitNumberPerCustomers } from './VisitNumberPerCustomers'
import { useToasts } from '../../../toasts/Toasts'
import { useIntl } from 'react-intl'

export const CustomersInsights: FC = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useFilters()
  const [customersData, setCustomersData] = useState<ICustomersInsights | null>(null)

  const { showToast } = useToasts()

  const getCustomers = async () => {
    try {
      setLoading(true)

      const { data: { data } } = await insightsService.getCustomers(filters)

      setCustomersData(data)
    } catch (err) {
      showToast()
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getCustomers()
  }, [filters])

  return (
    <>
      <PageTitle>
        {intl.formatMessage({ id: 'MENU.INSIGHTS.CUSTOMERS' })}
      </PageTitle>

      {
        (loading || !customersData)
          ? <AppLoading />
          : (
            <>
              <Filters
                filters={filters}
                setFilters={setFilters}
                availableYears={Object.keys(customersData.availableYears)}
              />

              <div className='row mt-7'>
                <div className='col-12'>
                  <RepeatVsOneTime data={customersData.repeat_customers_one_time_customers} />
                </div>

                {/* TODO: uncomment when get these values */}
                {/* <div className='col-12 col-lg-6 flex-fill mt-6 mt-lg-0'>
                  <SalesKPI />
                </div> */}
              </div>

              <div className='row mt-6'>
                <div className='col-12 col-lg-6 flex-fill'>
                  <SalesPerProductsQty
                    data={customersData.sales_per_products_quantity}
                    currency={customersData.currency}
                  />
                </div>

                <div className='col-12 col-lg-6 mt-6 mt-lg-0'>
                  <SalesPerPaymentType
                    data={customersData.sales_per_payment_type}
                    paymentTypes={customersData.payment_types}
                  />
                </div>
              </div>

              <div className='row mt-6'>
                <div className='col-12'>
                  <VisitNumberPerCustomers
                    data={customersData.visits_number_per_customers}
                    currency={customersData.currency}
                  />
                </div>
              </div>
            </>
          )
      }
    </>
  )
}