import { FC } from 'react'
import { useThemeMode } from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'

type TProps = {
  width?: string
}

export const AppLogo: FC<TProps> = ({ width = '100' }) => {
  const { mode } = useThemeMode()

  return (
    <img src={process.env.PUBLIC_URL + '/media/logos/' + (mode === 'dark' ? 'logo-dark.png' : 'logo-white.png')} alt="Logo" width={width} />
  )
}