import { Toast } from 'react-bootstrap'

import { createContext, FC, useContext, useState } from 'react'
import { WithChildren } from '../../../_metronic/helpers'
import { useIntl } from 'react-intl'

export interface IToast {
  text: string
  bg: string
  delay?: number
}

type TToastContextProps = {
  toast: IToast | null
  showToast: (d?: IToast) => void
}

const ToastsContext = createContext<TToastContextProps>({
  toast: null,
  showToast: () => {}
})

const useToasts = () => {
  return useContext(ToastsContext)
}

const ToastsProvider: FC<WithChildren> = ({ children }) => {
  const intl = useIntl()
  const [toastVisible, setToastVisible] = useState(false)
  const [toast, setToast] = useState<IToast | null>(null)

  const showToast = (d?: IToast) => {
    setToastVisible(true)
    setToast(d || { bg: 'danger', text: intl.formatMessage({ id: 'GLOBAL.SOMETHING_WENT_WRONG' }) })
  }

  return (
    <ToastsContext.Provider value={{ toast, showToast }}>
      {children}

      <Toast
        onClose={() => setToastVisible(false)}
        show={toastVisible}
        delay={toast?.delay || 3000}
        bg={toast?.bg || 'success'}
        autohide
      >
        <Toast.Header closeVariant='white'>
          <div className='py-1'>
            {toast?.text}

          </div>
        </Toast.Header>
      </Toast>
    </ToastsContext.Provider>
  )
}

export {
  useToasts,
  ToastsProvider
}