import { useIntl } from "react-intl";
import { AsideMenuItemWithSub } from "./AsideMenuItemWithSub";
import { AsideMenuItem } from "./AsideMenuItem";

export function AsideMenuMain() {
  const intl = useIntl();

  return (
    <>
      <AsideMenuItem
        to="/dashboard"
        icon="/media/icons/duotune/arrows/arr001.svg"
        title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
        fontIcon="bi-app-indicator"
      />

      <AsideMenuItemWithSub
        to="/insights"
        title={intl.formatMessage({ id: "MENU.INSIGHTS.ROOT" })}
        icon="/media/icons/duotune/arrows/arr001.svg"
      >
        <AsideMenuItem
          to="/insights/sales"
          icon="/media/icons/duotune/arrows/arr001.svg"
          title={intl.formatMessage({ id: "MENU.INSIGHTS.SALES" })}
        />
        <AsideMenuItem
          to="/insights/customers"
          icon="/media/icons/duotune/arrows/arr001.svg"
          title={intl.formatMessage({ id: "MENU.INSIGHTS.CUSTOMERS" })}
        />
        <AsideMenuItem
          to="/insights/top-products"
          icon="/media/icons/duotune/arrows/arr001.svg"
          title={intl.formatMessage({ id: "MENU.INSIGHTS.TOP_PRODUCTS" })}
        />
        <AsideMenuItem
          to="/receipts-list"
          icon="/media/icons/duotune/arrows/arr001.svg"
          title={intl.formatMessage({ id: "MENU.INSIGHTS.RECEIPTS" })}
          fontIcon="bi-app-indicator"
        />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to="/api-tokens"
        icon="/media/icons/duotune/arrows/arr001.svg"
        title={intl.formatMessage({ id: "MENU.MANAGE.ROOT" })}
      >
        <AsideMenuItem
          to="/api-tokens"
          icon="/media/icons/duotune/arrows/arr001.svg"
          title={intl.formatMessage({ id: "MENU.MANAGE.TOKENS" })}
          fontIcon="bi-app-indicator"
        />
        <AsideMenuItem
          to="/change-password"
          icon="/media/icons/duotune/arrows/arr001.svg"
          title={intl.formatMessage({ id: "MENU.MANAGE.CHANGE_PASSWORD" })}
          fontIcon="bi-app-indicator"
        />
        <AsideMenuItem
          to="/survey"
          icon="/media/icons/duotune/arrows/arr001.svg"
          title={intl.formatMessage({ id: "MENU.MANAGE.FEEDBACK" })}
          fontIcon="bi-app-indicator"
        />
      </AsideMenuItemWithSub>

      {/* <AsideMenuItem
        to='/default-banners'
        icon='/media/icons/duotune/arrows/arr001.svg'
        title={intl.formatMessage({ id: 'MENU.DEFAULT_BANNERS' })}
        fontIcon='bi-app-indicator'
      /> */}
    </>
  );
}
