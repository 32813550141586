import { FC, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { DateRangePicker } from '../../components/shared'
import { useToasts } from '../toasts/Toasts'
import { LocationFilter, ChartWithData, CurrentCredit, DashboardSkeleton } from './components'
import { dashboardService } from './core/dashboard.service'
import { ILocation, ILocationFilterItem } from './core/dashboard.types'

export const DashboardPage: FC = () => {
  const intl = useIntl()

  const [locations, setLocations] = useState<ILocation[]>([])
  const [currentCredit, setCurrentCredit] = useState('')
  const [loading, setLoading] = useState(true)
  const [locationFilter, setLocationFilter] = useState<ILocationFilterItem[]>([])
  const [dateRange, setDateRange] = useState<[Date, Date]>([new Date(Date.now() - 7 * 24 * 60 * 60 * 1000), new Date()])

  const { showToast } = useToasts()

  useEffect(() => {
    getData()
  }, [dateRange])

  const totalReport = useMemo<ILocation>(() => {
    return getTotalReport(locations)
  }, [locations])

  const filteredLocations = useMemo<ILocation[]>(() => {
    const output = locations.filter(r => {
      return locationFilter.find(b => b.id === r.id)?.enabled
    })

    if (locations.length > 1) {
      output.unshift(totalReport)
    }

    return output
  }, [locations, locationFilter])

  function createLocationFilter (locations: ILocation[]): ILocationFilterItem[] {
    return locations.map(({ id, name }) => ({ id, name, enabled: true }))
  }

  function getTotalReport (locations: ILocation[]) {
    return {
      ...locations.reduce((acc, item) => {
        acc.avgPerReceipt += item.avgPerReceipt
        acc.count += item.count
        acc.totalItems += item.totalItems
        acc.viewed += item.viewed

        return acc
      }, { ...locations[0] }),
      name: intl.formatMessage({ id: 'DASHBOARD.TOTAL' })
    }
  }

  const getData = async () => {
    setLoading(true)

    try {
      const { data: { data: { locations, payment } } } = await dashboardService.getData({ dateRange })

      setCurrentCredit(payment.current.title)
      setLocationFilter(createLocationFilter(locations))
      setLocations(locations)
    } catch (err) {
      showToast()
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'MENU.DASHBOARD' })}
      </PageTitle>

      {!loading && (
        <div className='mb-5 d-flex flex-column flex-sm-row align-items-start align-items-sm-center'>
          {
            locations.length > 1 && (
              <div className='me-sm-4'>
                <LocationFilter
                  locationFilter={locationFilter}
                  disabled={loading}
                  setLocationFilter={setLocationFilter}
                />
              </div>
            )
          }

          <div className='mt-2 mt-sm-0' style={{ width: '203px' }}>
            <DateRangePicker
              value={dateRange}
              disabled={loading}
              onChange={setDateRange}
            />
          </div>
        </div>
      )}

      {
        loading
          ? <DashboardSkeleton />
          : <>
            <CurrentCredit value={currentCredit} />

            <div className='row'>
              {filteredLocations.map((location) => (
                <div key={location.name} className='col-12 col-md-6 mt-5'>
                  <ChartWithData location={location} />
                </div>
              ))}
            </div>
          </>
      }
    </>
  )
}