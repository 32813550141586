import clsx from 'clsx'
import { toAbsoluteUrl } from '../../../helpers'
import { setLanguage, useLang } from '../../../i18n/Metronici18n'

type TProps = {
  toggleBtnClass?: string
  toggleBtnIconClass?: string
  menuPlacement?: string
  menuTrigger?: string
}

const languages = [
  {
    lang: 'en',
    name: 'English',
    flag: toAbsoluteUrl('/media/flags/united-states.svg')
  },
  {
    lang: 'zh',
    name: '中文',
    flag: toAbsoluteUrl('/media/flags/china.svg')
  },
  {
    lang: 'th',
    name: 'คนไทย',
    flag: toAbsoluteUrl('/media/flags/thailand.svg')
  },
  {
    lang: 'ar',
    name: 'العربية',
    flag: toAbsoluteUrl('/media/flags/arabic.png')
  },
]

export const LanguageSwitcher = ({
  toggleBtnClass = '',
  menuPlacement = 'bottom-end',
  menuTrigger = '{default: \'click\', xl: \'hover\'}'
}: TProps) => {
  const lang = useLang()
  const currentLanguage = languages.find((x) => x.lang === lang)

  return (
    <>
      {/* begin::Menu toggle */}
      <a
        href='#'
        className={clsx('btn btn-icon ', toggleBtnClass)}
        data-kt-menu-trigger={menuTrigger}
        data-kt-menu-attach='parent'
        data-kt-menu-placement={menuPlacement}
      >
        <img
          className='w-15px h-15px rounded-1'
          src={currentLanguage?.flag}
          alt='metronic'
        />
      </a>
      {/* begin::Menu toggle */}

      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px'
        data-kt-menu='true'
      >
        {languages.map((l) => (
          <div
            className='menu-item px-3'
            key={l.lang}
            onClick={() => {
              if (l.lang === lang) return

              setLanguage(l.lang)
            }}
          >
            <div
              className={clsx('menu-link d-flex px-5', { active: l.lang === currentLanguage?.lang })}
            >
              <span className='symbol symbol-20px'>
                <img className='rounded-1' src={l.flag} alt='metronic' />
              </span>

              <span className='ms-4'>
                {l.name}
              </span>
            </div>
          </div>
        ))}
      </div>
      {/* end::Menu */}
    </>
  )
}
