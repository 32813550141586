/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC } from "react";
import { ISurveyAnswer } from "../core/survey.types";

type TProps = {
  answer: ISurveyAnswer;
};

const CommentSurveyAnswer: FC<TProps> = ({ answer }) => {
  const answers = answer.answers as string[];
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h4 className="py-3">{answer.question.title}</h4>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="justify-content-center">
              <ul className="py-3">
                {answers.map((m) => {
                  return (
                    <>
                      <li>{m}</li>
                    </>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommentSurveyAnswer;
