/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC } from "react";
import { ISurveyAnswer, ISurveyFile } from "../core/survey.types";

type TProps = {
  answer: ISurveyAnswer;
};

const FileSurveyAnswer: FC<TProps> = ({ answer }) => {
  const answers = answer.answers as Array<ISurveyFile[]>;

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h4 className="py-3">{answer.question.title}</h4>
          </div>
          <div className="col-sm-12">
            <div className="justify-content-center">
              <div className="row py-3">
                {answers.map((ans) => {
                  return (
                    <>
                      <div className="col">
                        <div className="card d-flex" style={{ width: "18rem" }}>
                          <img
                            className="card-img-top survey-image m-auto"
                            src={ans[0].content}
                            alt={ans[0].name}
                          />
                          <div className="card-body">
                            <h5 className="card-title">{ans[0].name}</h5>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FileSurveyAnswer;
