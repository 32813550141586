import { useState } from 'react'
import { IFilters } from '../../types'
import { useAuth } from '../../modules/auth'

export const useFilters = (isDateRange = false, onlyLocation = false) => {
  const auth = useAuth()
  const output: IFilters = { location_id: null }

  if (onlyLocation) {
    if (Object.values(auth.currentProfile?.locations || {}).length) {
      output.location_id = parseInt(Object.keys(auth.currentProfile?.locations as object)[0])
    }
  } else {
    if (isDateRange) {
      output.dateRange = [new Date(Date.now() - 7 * 24 * 60 * 60 * 1000), new Date()]
    } else {
      output.month = '',
      output.year = new Date().getFullYear().toString()
    }
  }

  return useState<IFilters>(output)
}