import { FC, useMemo } from 'react'
import { KTCard, KTCardBody } from '../../../../_metronic/helpers'
import ContentLoader from 'react-content-loader'
import { useThemeMode } from '../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'

export const DashboardSkeleton: FC = () => {
  const { mode } = useThemeMode()

  const backgroundColor = useMemo(() => mode === 'dark' ? '#c4c4c4' : '#e6e6e6', [mode])
  const foregroundColor = useMemo(() => mode === 'dark' ? '#a3a2a2' : '#f7f7f7', [mode])

  return (
    <>
      <KTCard>
        <KTCardBody className='d-flex'>
          <ContentLoader
            speed={1.5}
            width={500}
            height={150}
            viewBox="0 0 500 150"
            backgroundColor={backgroundColor}
            foregroundColor={foregroundColor}
          >
            <rect x="14" y="10" rx="5" ry="5" width="249" height="24" />
            <rect x="19" y="56" rx="0" ry="0" width="182" height="40" />
          </ContentLoader>
        </KTCardBody>
      </KTCard>

      <div className='row'>

        {[1, 2, 3, 4].map(n => (

          <div key={n} className='col-12 col-md-6 mt-5'>
            <KTCard>
              <KTCardBody className='d-flex justify-content-center'>
                <ContentLoader
                  speed={1.5}
                  height={400}
                  viewBox="0 0 500 500"
                  backgroundColor={backgroundColor}
                  foregroundColor={foregroundColor}
                >
                  <circle cx="245" cy="157" r="99" />
                  <rect x="14" y="10" rx="5" ry="5" width="189" height="18" />
                  <rect x="82" y="319" rx="0" ry="0" width="65" height="30" />
                  <rect x="55" y="358" rx="0" ry="0" width="120" height="15" />
                  <rect x="343" y="317" rx="0" ry="0" width="65" height="30" />
                  <rect x="315" y="358" rx="0" ry="0" width="120" height="15" />
                  <rect x="83" y="398" rx="0" ry="0" width="65" height="30" />
                  <rect x="57" y="439" rx="0" ry="0" width="120" height="15" />
                  <rect x="344" y="397" rx="0" ry="0" width="65" height="30" />
                  <rect x="316" y="438" rx="0" ry="0" width="120" height="15" />
                </ContentLoader>
              </KTCardBody>
            </KTCard>
          </div>
        ))}
      </div>
    </>
  )
}