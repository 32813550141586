import { useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useAuth } from '../core/Auth'

export function Logout() {
  const { logout } = useAuth()

  useEffect(() => {
    logout()
    document.location.reload()
  }, [])

  return (
    <Routes>
      <Route index element={<Navigate to='/auth/login' />} />
    </Routes>
  )
}
