import { Dispatch, FC, SetStateAction, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { DateRangePicker, MonthSelect, YearSelect } from '.'
import { LocationSelect } from '../../modules/default-banners/components'
import { IFilters } from '../../types'
import { useAuth } from '../../modules/auth'
import clsx from 'clsx'

type TProps = {
  availableYears?: string[]
  filters: IFilters
  className?: string
  allowAllLocations?: boolean
  setFilters: Dispatch<SetStateAction<IFilters>>
}

interface IRow {
  prop: any
  label: string
  element: JSX.Element
  showFilter?(): boolean
}

export const Filters: FC<TProps> = ({ filters, availableYears, className, allowAllLocations = true, setFilters }) => {
  const intl = useIntl()
  const auth = useAuth()

  const rows = useMemo<IRow[]>(() => [
    {
      prop: filters.year,
      label: `${intl.formatMessage({ id: 'LABELS.SELECT_A_YEAR' })}:`,
      element: (
        <YearSelect
          value={filters.year as string}
          setValue={year => setFilters(prev => ({ ...prev, year }))}
          availableYears={availableYears as string[]}
        />
      )
    },
    {
      prop: filters.month,
      label: `${intl.formatMessage({ id: 'LABELS.SELECT_A_MONTH' })}:`,
      element: (
        <MonthSelect
          value={filters.month as string}
          setValue={month => setFilters(prev => ({ ...prev, month }))}
        />
      )
    },
    {
      prop: filters.dateRange,
      label: `${intl.formatMessage({ id: 'LABELS.SELECT_A_DATE_RANGE' })}:`,
      element: (
        <div className='w-200px'>
          <DateRangePicker
            value={filters.dateRange as [Date, Date]}
            onChange={dateRange => setFilters(prev => ({ ...prev, dateRange }))}
            small
          />
        </div>
      )
    },
    {
      prop: filters.location_id,
      label: `${intl.formatMessage({ id: 'LABELS.SELECT_A_LOCATION' })}:`,
      showFilter() {
        return Object.keys(auth.currentProfile?.locations || {}).length > 1
      },
      element: (
        <LocationSelect
          value={filters.location_id as number}
          setValue={location_id => setFilters(prev => ({ ...prev, location_id }))}
          allowAll={allowAllLocations}
        />
      )
    }
  ], [filters])

  return (
    <div className={clsx('d-flex flex-column gap-2 w-100 w-sm-75 w-md-50', className)}>
      {
        rows.map(({ prop, element, label, showFilter }) => {
          return prop !== undefined && (!showFilter || showFilter()) && (
            <div className='row' key={label}>
              <label className='form-label mb-0 col-5 d-flex align-items-center'>{label}</label>

              <div className='col-7'>
                {element}
              </div>
            </div>
          )
        })
      }
    </div>
  )
}