/* eslint-disable @typescript-eslint/no-explicit-any */
import clsx from "clsx";
import { FC, useEffect } from "react";

type TProps = {
	value: [Date, Date];
	disabled?: boolean;
	small?: boolean;
	onChange: (dateRange: [Date, Date]) => void;
};

export const DateRangePicker: FC<TProps> = ({
	value,
	disabled,
	small,
	onChange,
}) => {
	useEffect(() => {
		($('input[name="daterange"]') as any).daterangepicker(
			{
				opens: "bottom",
				startDate: value[0],
				endDate: value[1],
			},
			(d1: Date, d2: Date) => onChange([d1, d2])
		);
	}, []);

	return (
		<input
			type="text"
			name="daterange"
			className={clsx("form-control", { "form-control-sm": small })}
			disabled={disabled}
		/>
	);
};
