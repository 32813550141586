import React, { useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { authService } from "../core/auth.service";
import { useValidationSchema } from "../core/use-validation-schema.hook";
import { useIntl } from "react-intl";

const initialValues = {
  email: "admin@demo.com",
};

export function ForgotPassword() {
  const { emailValidationSchema } = useValidationSchema();
  const intl = useIntl();

  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean>(false);
  const [sent, setSent] = useState(false);

  const forgotPasswordSchema = Yup.object().shape({
    email: emailValidationSchema,
  });

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setLoading(true);
      setSubmitting(true);

      try {
        await authService.resetPassword(values.email);

        setSent(true);
        resetForm({ values: initialValues });
      } catch {
        setHasErrors(true);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      id="kt_login_password_reset_form"
      onSubmit={formik.handleSubmit}
    >
      <div className="text-center mb-10">
        <h1 className="text-dark mb-3">
          {intl.formatMessage({ id: "AUTH.FORGOT_PASSWORD" })}
        </h1>

        <div className="text-gray-400 fw-bold fs-4">
          {intl.formatMessage({ id: "AUTH.ENTER_EMAIL_TO_RESET_PASSWORD" })}
        </div>
      </div>

      {hasErrors && (
        <div className="mb-xl-15 alert alert-danger">
          <div className="alert-text font-weight-bold">
            {intl.formatMessage({ id: "GLOBAL.SOMETHING_WENT_WRONG" })}
          </div>
        </div>
      )}

      {sent && (
        <div className="mb-10 bg-light-info p-8 rounded">
          <div className="text-info">
            {intl.formatMessage({ id: "AUTH.RESET_SENT" })}
          </div>
        </div>
      )}

      <div className="fv-row mb-10">
        <label className="form-label fw-bolder text-gray-900 fs-6 is-required">
          {intl.formatMessage({ id: "AUTH.EMAIL" })}
        </label>
        <input
          type="email"
          placeholder=""
          autoComplete="off"
          {...formik.getFieldProps("email")}
          className={clsx(
            "form-control form-control-xl form-control-solid",
            { "is-invalid": formik.touched.email && formik.errors.email },
            {
              "is-valid": formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>

      <div className="d-flex flex-wrap justify-content-between pb-xl-0">
        <Link to="/auth/login">
          <button
            type="button"
            id="kt_login_password_reset_form_cancel_button"
            className="btn btn-xl btn-light-primary fw-bolder"
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {intl.formatMessage({ id: "BUTTONS.CANCEL" })}
          </button>
        </Link>
        <button
          type="submit"
          id="kt_password_reset_submit"
          className="btn btn-xl btn-primary fw-bolder me-4"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && (
            <span className="indicator-label">
              {intl.formatMessage({ id: "BUTTONS.SUBMIT" })}
            </span>
          )}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              {intl.formatMessage({ id: "GLOBALS.PLEASE_WAIT" })}
              <span className="spinner-border spinner-border-sm align-middle ms-2" />
            </span>
          )}
        </button>
      </div>
    </form>
  );
}
