import { axiosInstance } from '../../../core'
import { createRequestQuery } from '../../../core/helpers'
import { IFilters, IResponse } from '../../../types'
import { ITokensData } from './api-tokens.types'

class ApiTokensService {
  getTokens(f: IFilters) {
    return axiosInstance.get<IResponse<ITokensData>>(`/v1/location/details?${createRequestQuery(f)}`)
  }
}

export const apiTokensService = new ApiTokensService()