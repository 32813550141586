/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useMemo, useState } from 'react'
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Legend } from 'recharts'
import { IPieChartDataItem } from '../../types'

type TProps = {
  data: IPieChartDataItem[]
  withLegend?: boolean
}

export const ActiveShapePieChart: FC<TProps> = ({ data, withLegend = false }) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const isDataEmpty = useMemo<boolean>(() => {
    return data.every(d => d.value === 0)
  }, [data])

  const finalData = useMemo<IPieChartDataItem[]>(() => {
    if (isDataEmpty) {
      return [{ name: 'Empty', value: 100, fill: '#5E6278' }]
    }

    return data
  }, [data])

  const onPieEnter = (_: any, index: number) => {
    setActiveIndex(index)
  }

  const renderActiveShape = (props: any) => {
    const RADIAN = Math.PI / 180
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, percent, name } = props
    const sin = Math.sin(-RADIAN * midAngle)
    const cos = Math.cos(-RADIAN * midAngle)
    const sx = cx + (outerRadius + 10) * cos
    const sy = cy + (outerRadius + 10) * sin
    const mx = cx + (outerRadius + 30) * cos
    const my = cy + (outerRadius + 30) * sin
    const ex = mx + (cos >= 0 ? 1 : -1) * 13
    const ey = my
    const textAnchor = cos >= 0 ? 'start' : 'end'
    const formattedPercent = Math.floor(percent * 100)

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill} fontSize="20px">
          {isDataEmpty ? '0%' : `${formattedPercent}%` }
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        {!isDataEmpty && (
          <>
            <Sector
              cx={cx}
              cy={cy}
              startAngle={startAngle}
              endAngle={endAngle}
              innerRadius={outerRadius + 6}
              outerRadius={outerRadius + 10}
              fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill={fill}>{name}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
              {isDataEmpty ? '0%' : `${formattedPercent}%` }
            </text>
          </>
        )}
      </g>
    )
  }

  return (
    <div className='h-350px'>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={finalData}
            cx="50%"
            cy="50%"
            innerRadius={60}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
            onMouseEnter={onPieEnter}
          >
            {finalData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.fill || '#8884d8'} />
            ))}
          </Pie>

          {withLegend && !isDataEmpty && <Legend />}
        </PieChart>
      </ResponsiveContainer>
    </div>
  )
}

