/* eslint-disable @typescript-eslint/no-explicit-any */
import clsx from 'clsx'
import { FieldInputProps, FormikErrors, FormikTouched } from 'formik'
import { FC, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { MenuComponent } from '../../../../_metronic/assets/ts/components'
import { IMediaData } from '../../../types'

type TProps = {
  props: FieldInputProps<any>
  data: Omit<IMediaData, 'type'>
  errors?: FormikErrors<{ video: string }>
  touched?: FormikTouched<{ video: boolean }>
}

export const VideoUpload: FC<TProps> = (
  { props, touched, errors, data }
) => {
  const intl = useIntl()
  const [showPreviewTimeout, setShowPreviewTimeout] = useState<any>(null)
  const [previewURL, setPreviewUrl] = useState('')

  const getPreviewUrl = useMemo(() => {
    return `https://www.youtube.com/embed/${data.video}?rel=0&controls=0&showinfo=0`
  }, [data.video])

  useEffect(() => {
    if (!data.video) return

    setPreviewUrl(getPreviewUrl)
  }, [])

  useEffect(() => {
    clearTimeout(showPreviewTimeout)

    if (errors?.video || !data.video) {
      setPreviewUrl('')
      return
    }

    setShowPreviewTimeout(setTimeout(() => {
      setPreviewUrl(getPreviewUrl)
    }, 1000))
  }, [errors?.video, data.video])

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  return (
    <div className='row'>
      <div className='col-12 col-md-4'>
        <div className='h-200px w-100' style={{ borderRadius: '.65rem' }}>
          {
            previewURL
              ? <iframe src={previewURL} className='w-100 h-100' style={{ borderRadius: '.65rem' }} />
              : (
                <div
                  className='d-flex flex-column justify-content-center align-items-center
                    border border-gray-700 text-center h-100 w-100'
                  style={{ borderRadius: 'inherit' }}
                >
                  <i className="fa-solid fa-video fs-3x" />
                  <div className='text-gray-700 fw-bold mt-3'>
                    {intl.formatMessage({ id: 'DEFAULT_BANNERS.FILL_TO_SEE_PREVIEW' })}
                  </div>
                </div>
              )
          }
        </div>
      </div>
      <div className='col-12 col-md-8 mt-5 mt-md-0'>
        <div className='d-flex align-items-center'>
          <label className='form-label fs-6 fw-bolder mb-0'>
            {intl.formatMessage({ id: 'DEFAULT_BANNERS.YOUTUBE_VIDEO_ID' })}
          </label>

          <div className='ms-3'>
            <div
              className='badge badge-sm badge-circle border border-gray-700'
              data-kt-menu-trigger='hover'
              data-kt-menu-placement='bottom'
              data-kt-menu-flip='top-end'
            >
              <i className='fa-solid fa-info text-gray-700' />
            </div>

            <div className='menu menu-sub menu-sub-dropdown py-3 px-5' data-kt-menu='true'>
              <strong>
                {intl.formatMessage({ id: 'DEFAULT_BANNERS.YOUTUBE_VIDEO_ID_TOOLTIP.TITLE' })}
              </strong>
              <div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'DEFAULT_BANNERS.YOUTUBE_VIDEO_ID_TOOLTIP.BODY' }) }} />
            </div>
          </div>

        </div>
        <input
          type='text'
          className={clsx(
            'form-control form-control-lg form-control-solid mt-3',
            { 'is-invalid': touched?.video && errors?.video },
            { 'is-valid': touched?.video && !errors?.video }
          )}
          placeholder={intl.formatMessage({ id: 'DEFAULT_BANNERS.YOUTUBE_VIDEO_ID' })}
          {...(props || [])}
        />

        {touched?.video && errors?.video && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{errors.video}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}