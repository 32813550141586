import { FC, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { KTCard, KTCardBody } from '../../../../../_metronic/helpers'
import { ActiveShapePieChart } from '../../../../components/charts'
import { IPieChartDataItem } from '../../../../types'
import { ISalesDataItem } from '../../core/insights.types'

type TProps = {
  data: {[key: string]: ISalesDataItem}
  paymentTypes: {[key: string]: string}
}

const colorByPaymentId: {[key: string]: string} = {
  1: '#009ef7',
  2: '#50cd89',
  3: '#FFA600',
  4: '#7239ea',
  5: '#d9214e',
  6: '#3F4254',
  7: '#373773',
  8: '#0dcaf0',
  9: '#198754',
  100: '#d63384'
}

export const SalesPerPaymentType: FC<TProps> = ({ data, paymentTypes }) => {
  const intl = useIntl()
  const transformedData = useMemo<IPieChartDataItem[]>(() => {
    return Object.entries(data).map(([key, { count }]) => {
      return { name: paymentTypes[key], value: count, fill: colorByPaymentId[key] }
    })
  }, [data])

  return (
    <KTCard className='h-100'>
      <KTCardBody>
        <h3 className='card-title'>{intl.formatMessage({ id: 'CUSTOMERS_INSIGHTS.SALES_PER_PAYMENT_TYPE' })}</h3>

        <ActiveShapePieChart data={transformedData} withLegend />
      </KTCardBody>
    </KTCard>
  )
}