import { FC } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { PrivateRoutes } from "./PrivateRoutes";
import { ErrorsPage } from "../modules/errors/ErrorsPage";
import { AuthPage, useAuth } from "../modules/auth";
import { Logout } from "../modules/auth/components";
import { App } from "../App";
import { PreviewPage } from "../modules/preview/PreviewPage";
import RecoverPasswordPage from "../modules/auth/pages/RecoverPasswordPage";

const { PUBLIC_URL } = process.env;

const AppRoutes: FC = () => {
	const { auth } = useAuth();

	return (
		<BrowserRouter basename={PUBLIC_URL}>
			<Routes>
				<Route element={<App />}>
					<Route path="error/*" element={<ErrorsPage />} />
					<Route path="logout" element={<Logout />} />
					<Route path="preview" element={<PreviewPage />} />

					<Route
						path="user/recover/:id/:code"
						element={<RecoverPasswordPage />}
					/>

					{auth ? (
						<>
							<Route path="*" element={<PrivateRoutes />} />
							<Route index element={<Navigate to="/dashboard" />} />
						</>
					) : (
						<>
							<Route path="auth/*" element={<AuthPage />} />
							<Route path="*" element={<Navigate to="/auth" />} />
						</>
					)}
				</Route>
			</Routes>
		</BrowserRouter>
	);
};

export { AppRoutes };
