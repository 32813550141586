import { Outlet } from 'react-router-dom'
import { LayoutProvider } from '../_metronic/layout/core'
import { MasterInit } from '../_metronic/layout/MasterInit'
import { AuthInit } from './modules/auth'

const App = () => {
  return (
    <LayoutProvider>
      <AuthInit>
        <Outlet />
        <MasterInit />
      </AuthInit>
    </LayoutProvider>
  )
}

export { App }
