import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
  useMemo
} from 'react'
import { IAuthModel, IUserProfile } from './auth.types'
import * as authHelper from './auth.helpers'
import { WithChildren } from '../../../../_metronic/helpers'
import { AppLoading } from '../../../components/shared'
import { authService } from './auth.service'
import { useLocation } from 'react-router-dom'
import { useToasts } from '../../toasts/Toasts'

type TAuthContextProps = {
  auth: IAuthModel | null
  saveAuth: (auth: IAuthModel | null) => void
  currentProfile: IUserProfile | null
  setCurrentProfile: Dispatch<SetStateAction<IUserProfile | null>>
  logout: () => void
}

const initTAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentProfile: null,
  setCurrentProfile: () => {},
  logout: () => {}
}

const AuthContext = createContext<TAuthContextProps>(initTAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<IAuthModel | null>(authHelper.getAuth())
  const [currentProfile, setCurrentProfile] = useState<IUserProfile | null>(null)
  const saveAuth = (auth: IAuthModel | null) => {
    setAuth(auth)

    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(null)
    setCurrentProfile(null)
  }

  return (
    <AuthContext.Provider value={{ auth, saveAuth, currentProfile, setCurrentProfile, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth, logout, setCurrentProfile } = useAuth()
  const didRequest = useRef(false)
  const [loading, setLoading] = useState(true)
  const location = useLocation()
  const isLogout = useMemo<boolean>(() => {
    return location.pathname.includes('logout')
  }, [location])
  const { showToast } = useToasts()

  if (isLogout) return <>{children}</>

  useEffect(() => {
    if (loading) return

    document.body.classList.remove('page-loading')
  }, [loading])

  useEffect(() => {
    const requestProfile = async () => {
      try {
        if (!didRequest.current) {
          const { data: { details: profile } } = await authService.getProfile()

          if (profile) {
            setCurrentProfile(profile)
          }
        }
      } catch (error) {
        showToast()

        if (!didRequest.current) {
          logout()
        }
      } finally {
        setTimeout(() => {
          setLoading(false)
        }, 1000)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.auth_token) {
      requestProfile()
    } else {
      setLoading(false)
      logout()
    }
  }, [])

  return loading ? <AppLoading fullScreen /> : <>{children}</>
}

export { AuthProvider, AuthInit, useAuth }
