import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'
import { getAuth } from '../modules/auth'

const { REACT_APP_API_URL } = process.env

export type TMockCB = (adapter: MockAdapter) => void

export const axiosInstance = axios.create({
  baseURL: REACT_APP_API_URL
})

export const mockAdapter = new MockAdapter(axiosInstance)

// import mocks from modules (mocks = MockCB[]) and call each mock passing mockAdapter via arguments

mockAdapter.onAny().passThrough()

axiosInstance.interceptors.request.use(
  (config) => {
    const auth = getAuth()

    if (config.headers) {
      if (auth && auth.auth_token) {
        config.headers.Authorization = `Bearer ${auth.auth_token}`
      }
    }

    return config
  },
  (err: Error) => Promise.reject(err)
)