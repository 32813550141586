import { Dispatch, FC, SetStateAction } from 'react'
import { useIntl } from 'react-intl'
import { EMediaType } from '../../../types'

type TProps = {
  label: string
  value: EMediaType,
  setValue: Dispatch<SetStateAction<EMediaType>>
}

const labelByType = {
  [EMediaType.Image]: 'IMAGE',
  [EMediaType.Video]: 'YOUTUBE_VIDEO',
  [EMediaType.None]: 'NONE'
}

export const MediaTypeSelect: FC<TProps> = ({ label, value, setValue }) => {
  const intl = useIntl()

  return (
    <>
      <label className='form-label fs-4 fw-bolder'>
        {label}
      </label>
      <select
        name='mediaType'
        aria-label='Select a media type'
        className='form-select form-select-sm form-select-solid w-200px'
        data-placeholder={label}
        value={value}
        onChange={e => setValue(e.target.value as EMediaType)}
      >
        {Object.values(EMediaType).map(v => (
          <option key={v} value={v}>
            {intl.formatMessage({ id: `DEFAULT_BANNERS.${labelByType[v]}` })}
          </option>
        ))}
      </select>
    </>
  )
}