import { languageList } from "./data";

export class LanguageRequestsHelper {
	public static getCodeById(id: string) {
		const item = languageList.find((item: any) => item.id === id);

		if (item) {
			return item.code;
		}
	}
}
