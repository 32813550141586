import { FC, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { KTCard, KTCardBody } from '../../../../_metronic/helpers'
import { divideWithComma } from '../../../core/helpers'
import { ISalesDataItem } from '../core/insights.types'

type TProps = {
  products: {[key: string]: ISalesDataItem}
  currency: string
}

export const TopProducts: FC<TProps> = ({ products, currency }) => {
  const intl = useIntl()

  const isEmpty = useMemo<boolean>(() => {
    return Object.values(products).every(({ amount, count }) => !amount && !count)
  }, [products])

  return (
    <KTCard className='h-100'>
      <KTCardBody className='d-flex flex-column'>
        <h3 className='card-title'>{intl.formatMessage({ id: 'SALES_INSIGHTS.TOP_PRODUCTS' })}</h3>

        <div className='d-flex flex-column justify-content-center flex-fill mt-5'>
          {
            isEmpty
              ? <div className='fs-3x text-gray-500 fw-bold'>{intl.formatMessage({ id: 'GLOBAL.NO_DATA' })}</div>
              : (
                <div className='table-responsive'>
                  <table className="table table-striped mb-0">
                    <thead>
                      <tr className='fw-bold fs-5'>
                        <th scope="col" />
                        <th scope="col">{intl.formatMessage({ id: 'GLOBAL.PRODUCT' })}</th>
                        <th scope="col">{intl.formatMessage({ id: 'GLOBAL.TOTAL_SALES' })}</th>
                        <th scope="col">{intl.formatMessage({ id: 'GLOBAL.QTY' })}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(products).map(([name, data], i) => (
                        <tr key={name}>
                          <th scope="row" className='ps-2 pe-0 fw-bold'>{++i}.</th>
                          <td>{name}</td>
                          <td>{divideWithComma(data.amount)} {currency}</td>
                          <td>{data.count}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )
          }
        </div>
      </KTCardBody>
    </KTCard>
  )
}