import { useState } from "react";
import { ICodeBlock } from "../types";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";

const LanguageCodeBlock = ({ code, language, showLineNumbers }: ICodeBlock) => {
	const [copiedToClipboardMsg, setCopiedToClipboardMsg] = useState(false);

	const handleCopyCode = () => {
		navigator.clipboard.writeText(code);

		setCopiedToClipboardMsg(true);

		setTimeout(() => {
			setCopiedToClipboardMsg(false);
		}, 2000);
	};

	return (
		<div className="code-block">
			<div className="code-header">
				<span>Code</span>
				<button onClick={handleCopyCode}>
					{copiedToClipboardMsg ? (
						<>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="25"
								height="25"
								viewBox="0 0 24 24"
							>
								<g
									fill="none"
									stroke="currentColor"
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
								>
									<path d="m12 15l2 2l4-4" />
									<rect width="14" height="14" x="8" y="8" rx="2" ry="2" />
									<path d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2" />
								</g>
							</svg>
							Copied
						</>
					) : (
						<>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="25"
								height="25"
								viewBox="0 0 24 24"
							>
								<path
									fill="white"
									d="M9 18q-.825 0-1.413-.588T7 16V4q0-.825.588-1.413T9 2h9q.825 0 1.413.588T20 4v12q0 .825-.588 1.413T18 18H9Zm-4 4q-.825 0-1.413-.588T3 20V6h2v14h11v2H5Z"
								/>
							</svg>
							Copy Code
						</>
					)}
				</button>
			</div>
			<SyntaxHighlighter
				language={language}
				style={atomOneDark}
				showLineNumbers={showLineNumbers}
				wrapLines={true}
				customStyle={{
					padding: "1rem",
					borderRadius: "0 0 8px 8px",
				}}
			>
				{code}
			</SyntaxHighlighter>
		</div>
	);
};

export default LanguageCodeBlock;
