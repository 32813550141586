import { FC } from 'react'

type TProps = {
  name: string
}

export const Avatar: FC<TProps> = ({ name }) => {
  const title = name ? name.split(' ').reduce((acc, val) => acc += val[0], '') : 'AA'

  return (
    <div
      className='d-flex align-items-center justify-content-center w-45px
        h-45px text-white bg-primary rounded-circle fw-semibold fs-3'
      style={{ textTransform: 'uppercase' }}
    >
      {title}
    </div>
  )
}