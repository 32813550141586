import { FC, useEffect, useMemo, useState } from "react";
import { PageTitle } from "../../../_metronic/layout/core";
import { apiTokensService } from "./core/api-tokens.service";
import { useFilters } from "../../core/hooks";
import { ITokensData } from "./core/api-tokens.types";
import { KTCard, KTCardBody } from "../../../_metronic/helpers";
import { AppLoading, Filters } from "../../components/shared";
import { useIntl } from "react-intl";
import { useToasts } from "../toasts/Toasts";
import { useAuth } from "../auth";
import { CodeBlock } from "../../../_metronic/partials";
import apiTokensData from "./core/data.json";
import LanguageRequests from "./languages-request/components/LanguageRequests";

export const ApiTokensPage: FC = () => {
  const intl = useIntl();
  const auth = useAuth();
  const toast = useToasts();
  const [filters, setFilters] = useFilters(false, true);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<ITokensData | null>(null);

  const requestHeadersSample = useMemo(() => {
    return {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${data?.oauth.access_token.access_token}`,
    };
  }, [data]);

  useEffect(() => {
    getTokens();
  }, [filters.location_id]);

  const tokensPayloadSample = useMemo(() => {
    if (!data) return;

    const { client_id, client_secret, grant_type } = data.oauth.client;

    return {
      client_id,
      client_secret,
      grant_type,
    };
  }, [data]);
  const tokensResponseSample = useMemo(() => {
    if (!data) return;

    const { access_token, expires } = data.oauth.access_token;

    return {
      access_token,
      expires_in: expires,
      token_type: "bearer",
    };
  }, [data]);

  async function getTokens() {
    setLoading(true);

    try {
      const res = await apiTokensService.getTokens(filters);
      setData(res.data.data);
    } catch {
      toast.showToast();
    } finally {
      setLoading(false);
    }
  }

  console.log(auth);

  return (
    <>
      <PageTitle>{intl.formatMessage({ id: "MENU.MANAGE.TOKENS" })}</PageTitle>

      {Object.keys(auth.currentProfile?.locations || {}).length > 1 && (
        <Filters
          className="mb-6"
          filters={filters}
          setFilters={setFilters}
          allowAllLocations={false}
        />
      )}

      <KTCard>
        <KTCardBody>
          {loading ? (
            <AppLoading className="h-500px" />
          ) : (
            <div>
              <ul className="nav nav-tabs mb-10 nav-fill" role="tablist">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-bs-toggle="tab"
                    href="#api-keys-tab-1"
                  >
                    <h3>{intl.formatMessage({ id: "API_TOKENS.TOKENS" })}</h3>
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-bs-toggle="tab"
                    href="#api-keys-tab-2"
                  >
                    <h3>
                      {intl.formatMessage({ id: "API_TOKENS.SAMPLE_REQUEST" })}
                    </h3>
                  </a>
                </li>
              </ul>

              {data ? (
                <div className="tab-content px-5">
                  <div
                    className="tab-pane fade active show"
                    id="api-keys-tab-1"
                    role="tabpanel"
                  >
                    <div className="d-flex flex-column">
                      <div className="row align-items-center mt-2">
                        <div className="col-12 col-md-4">
                          <h2>
                            {intl.formatMessage({ id: "API_TOKENS.TOKENS" })}:
                          </h2>
                        </div>
                        <div className="col-12 col-md-8">
                          <CodeBlock
                            code={
                              JSON.stringify(data?.oauth, null, 4) as string
                            }
                            language="javascript"
                          />
                        </div>
                      </div>
                      <div className="row align-items-center mt-2">
                        <div className="col-12 col-md-4">
                          <h2>
                            {intl.formatMessage({ id: "API_TOKENS.API_URL" })}:
                          </h2>
                        </div>
                        <div className="col-12 col-md-8">
                          <CodeBlock
                            code={apiTokensData.TOKENS_REQUEST_URL}
                            language="markup"
                          />
                        </div>
                      </div>
                      <div className="row align-items-center mt-2">
                        <div className="col-12 col-md-4">
                          <h2>
                            {intl.formatMessage({
                              id: "API_TOKENS.REQUEST_BODY",
                            })}
                            :
                          </h2>
                        </div>
                        <div className="col-12 col-md-8">
                          <CodeBlock
                            code={JSON.stringify(tokensPayloadSample, null, 4)}
                            language="javascript"
                          />
                        </div>
                      </div>
                      <div className="row align-items-center mt-2">
                        <div className="col-12 col-md-4">
                          <h2>
                            {intl.formatMessage({
                              id: "API_TOKENS.SAMPLE_SUCCESS_RESPONSE",
                            })}
                            :
                          </h2>
                        </div>
                        <div className="col-12 col-md-8">
                          <CodeBlock
                            code={JSON.stringify(tokensResponseSample, null, 4)}
                            language="javascript"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="api-keys-tab-2"
                    role="tabpanel"
                  >
                    <LanguageRequests />
                    <div className="row align-items-center mt-2">
                      <div className="col-12 col-md-4">
                        <h3>
                          {intl.formatMessage({ id: "API_TOKENS.API_URL" })}:
                        </h3>
                      </div>
                      <div className="col-12 col-md-8">
                        <CodeBlock
                          code={apiTokensData.REQUEST_URL_SAMPLE}
                          language="markup"
                        />
                      </div>
                    </div>
                    <div className="row align-items-center mt-2">
                      <div className="col-12 col-md-4">
                        <h3>
                          {intl.formatMessage({ id: "API_TOKENS.HTTP_METHOD" })}
                          :
                        </h3>
                      </div>
                      <div className="col-12 col-md-8">
                        <CodeBlock code={"'POST'"} language="javascript" />
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <div className="col-12 col-md-4">
                        <h3>
                          {intl.formatMessage({
                            id: "API_TOKENS.REQUEST_HEADERS",
                          })}
                          :
                        </h3>
                      </div>
                      <div className="col-12 col-md-8">
                        <CodeBlock
                          code={JSON.stringify(requestHeadersSample, null, 4)}
                          language="javascript"
                        />
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <div className="col-12 col-md-4">
                        <h3>
                          {intl.formatMessage({
                            id: "API_TOKENS.REQUEST_BODY",
                          })}
                          :
                        </h3>
                      </div>
                      <div className="col-12 col-md-8">
                        <CodeBlock
                          code={JSON.stringify(
                            {
                              receipts: [
                                {
                                  locationId: filters.location_id,
                                  ticketNumber: "CK12019",
                                  invoiceNo: "5555566",
                                  createDateTime: "2023-05-29T10:01:00.000",
                                  closedDateTime: "2023-05-29T10:02:00.000",
                                  invoiceType: 1,
                                  ticketTotal: 246.0,
                                  itemsCount: "8",
                                  version: 5,
                                  cashier: "Raj",
                                  customer: {
                                    mobileNo: "+918531024682",
                                    whatsApp: "+918531024682",
                                    email: auth.currentProfile?.user.email,
                                    firstName:
                                      auth.currentProfile?.entities[0].name.split(
                                        " "
                                      )[0],
                                    lastName:
                                      auth.currentProfile?.entities[0].name.split(
                                        " "
                                      )[1],
                                  },
                                  open: {
                                    color: "MX12",
                                    size: "423",
                                  },
                                  payments: [
                                    {
                                      code: "2",
                                      name: {
                                        ar: "Premium",
                                        en: "",
                                      },
                                      paidAmount: "246.00",
                                      tenderAmount: "246.00",
                                      open: {
                                        color: "MX12",
                                        size: "423",
                                      },
                                    },
                                  ],
                                  taxes: [
                                    {
                                      code: "2",
                                      name: {
                                        ar: "Premium",
                                        en: "",
                                      },
                                      taxableAmount: "1200",
                                      amount: "32.09",
                                      taxPercentage: "15",
                                      open: {
                                        color: "MX12",
                                        size: "423",
                                      },
                                    },
                                  ],
                                  discounts: [
                                    {
                                      code: "2",
                                      name: {
                                        ar: "Premium",
                                        en: "",
                                      },
                                      amount: "0.00",
                                      open: {
                                        color: "MX12",
                                        size: "423",
                                      },
                                    },
                                  ],
                                  charges: [
                                    {
                                      code: "2",
                                      name: {
                                        ar: "Premium",
                                        en: "",
                                      },
                                      amount: "0.00",
                                      open: {
                                        color: "MX12",
                                        size: "423",
                                      },
                                    },
                                    {
                                      code: "2",
                                      name: {
                                        ar: "Premium",
                                        en: "",
                                      },
                                      amount: "0.00",
                                      open: {
                                        color: "MX12",
                                        size: "423",
                                      },
                                    },
                                  ],
                                  items: [
                                    {
                                      itemCode: "0002",
                                      name: {
                                        ar: "Premium",
                                        en: "",
                                      },
                                      category: {
                                        code: "001",
                                        name: {
                                          ar: "Ticket",
                                          en: "",
                                        },
                                        open: {
                                          color: "MX12",
                                          size: "423",
                                        },
                                      },
                                      qty: "2",
                                      price: "65.000",
                                      taxes: [
                                        {
                                          code: "2",
                                          name: {
                                            ar: "Premium",
                                            en: "",
                                          },
                                          amount: "32.09",
                                          taxPercentage: "15",
                                          taxableAmount: "324.534",
                                          open: {
                                            color: "MX12",
                                            size: "423",
                                          },
                                        },
                                      ],
                                      taxTotal: "16.960",
                                      discountTotal: "0.000",
                                      discounts: [
                                        {
                                          code: "2",
                                          name: {
                                            ar: "Premium",
                                            en: "",
                                          },
                                          amount: "0.00",
                                        },
                                      ],
                                      itemTotal: "130.000",
                                      attributes: {
                                        warranty: {
                                          applicable: true,
                                          warrantyStarts: "2024-10-10",
                                          warrantyExpiry: "2025-10-10",
                                        },
                                        open: {
                                          color: "MX12",
                                          size: "423",
                                        },
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                            null,
                            4
                          )}
                          language="javascript"
                        />
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <div className="col-12 col-md-4">
                        <h3>
                          {intl.formatMessage({
                            id: "API_TOKENS.SAMPLE_SUCCESS_RESPONSE",
                          })}
                          :
                        </h3>
                      </div>
                      <div className="col-12 col-md-8">
                        <CodeBlock
                          code={JSON.stringify(
                            apiTokensData.SUCCESS_RESPONSE_SAMPLE,
                            null,
                            4
                          )}
                          language="javascript"
                        />
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <div className="col-12 col-md-4">
                        <h3>
                          {intl.formatMessage({
                            id: "API_TOKENS.SAMPLE_FAILED_RESPONSE",
                          })}{" "}
                          1:
                        </h3>
                      </div>
                      <div className="col-12 col-md-8">
                        <CodeBlock
                          code={JSON.stringify(
                            apiTokensData.FAILED_VALIDATION_RESPONSE_1,
                            null,
                            4
                          )}
                          language="javascript"
                        />
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <div className="col-12 col-md-4">
                        <h3>
                          {intl.formatMessage({
                            id: "API_TOKENS.SAMPLE_FAILED_RESPONSE",
                          })}{" "}
                          2:
                        </h3>
                      </div>
                      <div className="col-12 col-md-8">
                        <CodeBlock
                          code={JSON.stringify(
                            apiTokensData.FAILED_VALIDATION_RESPONSE_2,
                            null,
                            4
                          )}
                          language="javascript"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="fs-2x text-gray-500 fw-bold">
                  {intl.formatMessage({ id: "GLOBAL.NO_RESULTS" })}
                </div>
              )}
            </div>
          )}
        </KTCardBody>
      </KTCard>
    </>
  );
};
