import { FC, useEffect, useMemo, useState } from "react";
import { Avatar } from "../../../../app/components/shared";
import { useAuth } from "../../../../app/modules/auth";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import { UserMenu } from "../user-menu/UserMenu";
import ConfirmLogout from "../../modals/confirm-logout/ConfirmLogout";

const AsideUserMenu: FC = () => {
  const { currentProfile, logout } = useAuth();
  const [showLogout, setShowLogout] = useState(false);

  const onLogout = () => {
    setShowLogout(true);
  };

  const handleLogout = (resp: boolean) => {
    setShowLogout(resp);
    resp && logout();
  };

  return (
    <>
      <div className="separator border-gray-400 my-5" />
      <div className="d-flex flex-stack">
        {/* begin::Wrapper */}
        <div className="d-flex align-items-center">
          {/* begin::Avatar */}
          <Avatar name={currentProfile?.user.username || ""} />
          {/* end::Avatar */}
          {/* begin::User info */}
          <div className="ms-3">
            <span className="text-gray-800 fs-6 fw-bolder lh-1">
              {currentProfile?.user.username}
            </span>
            {/* <span className='text-muted fw-bold d-block fs-7 lh-1'>Python Dev</span> */}
          </div>
          {/* end::User info */}
        </div>
        {/* end::Wrapper */}

        <button className="btn p-0 text-hover-primary" onClick={onLogout}>
          <i className="fa-solid fa-arrow-right-from-bracket" />
        </button>

        {/* begin::User menu */}
        {/* <div className='ms-1'>
          <div
            className='btn btn-sm btn-icon btn-active-color-primary position-relative me-n2'
            data-kt-menu-trigger='click'
            data-kt-menu-overflow='false'
            data-kt-menu-placement='top-end'
          >
            <KTSVG path='/media/icons/duotune/coding/cod001.svg' className='svg-icon-1' />
          </div>
          <UserMenu />
        </div> */}
        {/* end::User menu */}
      </div>

      {showLogout && (
        <ConfirmLogout logoutUser={(event: boolean) => handleLogout(event)} />
      )}
    </>
  );
};

export { AsideUserMenu };
