import { FC, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";
import { AppLoading, Filters } from "../../components/shared";
import { useFilters } from "../../core/hooks";
import { IFilters } from "../../types";
import { useToasts } from "../toasts/Toasts";
import { StatusOverall } from "./components";
import { ReceiptsTable } from "./components/ReceiptsTable";
import { receiptsListService } from "./core/receipts-list.service";
import {
	EReceiptsListStatus,
	ESortBy,
	IPageData,
	IReceiptItem,
	IReceiptsListData,
} from "./core/receipts-list.types";

export const ReceiptsListPage: FC = () => {
	const intl = useIntl();
	const [pageLoading, setPageLoading] = useState(true);
	const [tableLoading, setTableLoading] = useState(false);

	const [receiptsListData, setReceiptsListData] =
		useState<IReceiptsListData | null>(null);

	const [filters, setFilters] = useFilters(true);
	const [filterByStatus, setFilterByStatus] = useState<EReceiptsListStatus>(
		EReceiptsListStatus.All
	);
	const [sortBy, setSortBy] = useState<ESortBy | null>(null);
	const [sortOrder, setSortOrder] = useState<1 | -1>(1);
	const [pageData, setPageData] = useState<IPageData>({
		total_count: 0,
		limit: 0,
		offset: 0,
		page_count: 0,
		page_size: 5,
	});

	const { showToast } = useToasts();

	const filteredAndSortedItems = useMemo<IReceiptItem[]>(() => {
		if (!receiptsListData?.items || !filterByStatus) return [];

		const output = structuredClone(receiptsListData.items);

		if (sortBy) {
			output.sort((a, b) => {
				if (sortBy === ESortBy.ReceiptDate) {
					const dateA = new Date(a.date_time).getTime();
					const dateB = new Date(b.date_time).getTime();
					return sortOrder * (dateA - dateB);
				} else if (sortBy === ESortBy.InvoiceNo) {
					return sortOrder * (a.invoice_no - b.invoice_no);
				}

				return 0;
			});
		}

		return output;
	}, [filterByStatus, receiptsListData, sortBy, sortOrder]);

	const changeSort = (v: ESortBy) => {
		if (sortBy === v) {
			setSortOrder(cur => (cur - cur * 2) as 1 | -1);
			return;
		}

		setSortBy(v);
	};

	const getReceiptsList = async (
		filters: IFilters,
		pageData: IPageData,
		filterByStatus: EReceiptsListStatus
	) => {
		try {
			const {
				data: { data },
			} = await receiptsListService.getReceipts({
				...filters,
				filter: filterByStatus,
				page: Math.floor(pageData.offset / pageData.page_size) + 1,
				per_page: pageData.page_size,
			});

			setPageData(data.page);
			setReceiptsListData(data);
		} catch (err) {
			showToast();
		} finally {
			setTableLoading(false);
			setPageLoading(false);
		}
	};

	const onStatusChange = (status: EReceiptsListStatus) => {
		setFilterByStatus(() => {
			if (filterByStatus || status !== EReceiptsListStatus.All) {
				setTableLoading(true);
				getReceiptsList(filters, pageData, status);
			}

			return status;
		});
	};

	const toNextPage = () => {
		const { offset, page_size, total_count } = pageData;

		if (offset + page_size >= total_count) return;

		setPageData(cur => {
			const updatedPageData = {
				...cur,
				offset: cur.offset + cur.page_size,
			};

			setTableLoading(true);
			getReceiptsList(filters, updatedPageData, filterByStatus);

			return updatedPageData;
		});
	};

	const toPrevPage = () => {
		const { offset } = pageData;

		if (offset === 0) return;

		setPageData(cur => {
			const updatedPageData = {
				...cur,
				offset: cur.offset - cur.page_size,
			};

			setTableLoading(true);
			getReceiptsList(filters, updatedPageData, filterByStatus);

			return updatedPageData;
		});
	};

	const changeCountPerPage = (count: number) => {
		if (count === pageData.page_size) return;

		setPageData(cur => {
			const updatedPageData = {
				...cur,
				page_size: count,
			};

			setTableLoading(true);
			getReceiptsList(filters, updatedPageData, filterByStatus);

			return updatedPageData;
		});
	};

	useEffect(() => {
		setPageLoading(true);
		getReceiptsList(filters, pageData, filterByStatus);
	}, [filters]);

	return (
		<>
			<PageTitle>{intl.formatMessage({ id: "MENU.RECEIPTS_LIST" })}</PageTitle>

			{receiptsListData && (
				<Filters filters={filters} setFilters={setFilters} />
			)}

			{pageLoading || !receiptsListData ? (
				<AppLoading />
			) : (
				<>
					<div className="row mt-6">
						{Object.entries(receiptsListData.statistics).map(
							([type, value]) => (
								<div className="col-12 col-lg-3 mt-6 mt-lg-0" key={type}>
									<StatusOverall
										type={type as EReceiptsListStatus}
										value={value}
										total={receiptsListData.statistics.all}
										onClick={() =>
											value && onStatusChange(type as EReceiptsListStatus)
										}
									/>
								</div>
							)
						)}
					</div>

					{filterByStatus && (
						<div className="mt-6">
							<ReceiptsTable
								items={filteredAndSortedItems}
								sortOrder={sortOrder}
								pageData={pageData}
								loading={tableLoading}
								sortBy={sortBy}
								toNextPage={toNextPage}
								toPrevPage={toPrevPage}
								changeCountPerPage={changeCountPerPage}
								changeSort={changeSort}
							/>
						</div>
					)}
				</>
			)}
		</>
	);
};
