/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useAuth } from '../../auth'

type TProps = {
  value: number | null
  setValue: (value: number | null) => void
  allowAll?: boolean
}

export const LocationSelect: FC<TProps> = ({ value, allowAll, setValue }) => {
  const intl = useIntl()
  const { currentProfile } = useAuth()

  useEffect(() => {
    // @ts-ignore
    $('#location-select').select2({
      language: {
        noResults: () => intl.formatMessage({ id: 'GLOBAL.NO_LOCATIONS_FOUND' })
      }
    })

    // @ts-ignore
    $('#location-select').on('select2:select', (e) => {
      const { value } = e.target as HTMLSelectElement

      if (value === 'All') {
        setValue(null)
        return
      }

      setValue(parseInt(value))
    })
  }, [])

  if (Object.keys(currentProfile?.locations || {}).length <= 1) return null

  return (
    <div>
      <select
        name='location'
        id='location-select'
        className='form-select form-select-sm w-200px'
        data-placeholder={intl.formatMessage({ id: 'LABELS.SELECT_A_LOCATION' })}
        defaultValue={value || 'All'}
        disabled={Object.entries(currentProfile?.locations || {}).length <= 1}
      >
        {allowAll && (
          <option value='All'>
            {intl.formatMessage({ id: 'GLOBAL.ALL' })}
          </option>
        )}
        {
          Object.entries(currentProfile?.locations || {}).map(([id, name]) => {
            return (
              <option key={name} value={id}>
                {name}
              </option>
            )
          })
        }
      </select>
    </div>
  )
}
