import { FC } from 'react'
import { useLang } from './Metronici18n'
import { IntlProvider } from 'react-intl'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/de'
import '@formatjs/intl-relativetimeformat/locale-data/es'
import '@formatjs/intl-relativetimeformat/locale-data/fr'
import '@formatjs/intl-relativetimeformat/locale-data/ja'
import '@formatjs/intl-relativetimeformat/locale-data/zh'

import enMessages from './messages/en.json'
import zhMessages from './messages/zh.json'
import thMessages from './messages/th.json'
import arMessages from './messages/ar.json'

import { WithChildren } from '../helpers'

const allMessages = {
  en: enMessages,
  zh: zhMessages,
  th: thMessages,
  ar: arMessages
}

function flattenMessages(nestedMessages: { [key: string]: any }, prefix = '') {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    let value = nestedMessages[key]
    let prefixedKey = prefix ? `${prefix}.${key}` : key

    if (typeof value === 'string') {
      // @ts-ignore
      messages[prefixedKey] = value
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey))
    }

    return messages
  }, {})
}

const I18nProvider: FC<WithChildren> = ({ children }) => {
  const locale = useLang()
  const messages = flattenMessages(allMessages[locale])

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  )
}

export { I18nProvider }
