import { FC, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useLocation, useNavigate } from 'react-router-dom'
import { Preview } from '../../components/shared'
import { EMediaType } from '../../types'
import { previewService } from './core/preview.service'

export const PreviewPage: FC = () => {
  const intl = useIntl()
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    try {
      const urlSearchParams = new URLSearchParams(location.search)
      const params = Object.fromEntries(urlSearchParams.entries())

      if (!params.id) throw Error()

      previewService.getItem(params.id)
    } catch {
      navigate('/')
    }
  }, [location])

  return <div className='d-flex flex-column w-screen h-screen bg-light-primary pb-20 px-5'>
    <div className='d-flex align-items-center justify-content-between mb-20 pt-5'>
      <strong className="fs-1 text-dark">Cusjor</strong>

      <button className='btn btn-link fs-2' onClick={() => navigate('/')}>
        {intl.formatMessage({ id: 'BUTTONS.HOME' })}
      </button>
    </div>

    <Preview
      header={{
        image: 'https://avatars.githubusercontent.com/u/78264497?s=40&v=4',
        image_action_url: 'www.google.com',
        video: '',
        type: EMediaType.Image
      }}
      footer={{
        image: 'https://avatars.githubusercontent.com/u/78264497?s=40&v=4',
        image_action_url: 'www.google.com',
        video: '',
        type: EMediaType.Image
      }}
    />
  </div>
}