import { ILanguageItem } from "../../types";
import "../../styles.css";
import LanguageItem from "./LanguageItem";
import { useState } from "react";
import { languageList } from "../../data";

const LanguageList = ({ activeLanguage, setActiveLanguage }: any) => {
	const [moreLanguagesIsOpen, setMoreLanguagesIsOpen] = useState(false);

	return (
		<>
			<div className="language-list">
				{languageList &&
					languageList
						.slice(0, 4)
						.map((item: ILanguageItem) => (
							<LanguageItem
								key={item.id}
								id={item.id}
								image={item.image}
								label={item.label}
								activeLanguage={activeLanguage}
								toggle={setActiveLanguage}
								setMoreLanguagesIsOpen={setMoreLanguagesIsOpen}
							/>
						))}

				<div className="more-languages">
					<button
						className="menu-btn"
						onClick={() => setMoreLanguagesIsOpen(!moreLanguagesIsOpen)}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="32"
							height="32"
							viewBox="0 0 24 24"
						>
							<path
								fill="#5E6278"
								fillRule="evenodd"
								d="M3.464 3.464C2 4.93 2 7.286 2 12c0 4.714 0 7.071 1.464 8.535C4.93 22 7.286 22 12 22c4.714 0 7.071 0 8.535-1.465C22 19.072 22 16.714 22 12s0-7.071-1.465-8.536C19.072 2 16.714 2 12 2S4.929 2 3.464 3.464ZM12 13a1 1 0 1 0 0-2a1 1 0 0 0 0 2Zm-3-1a1 1 0 1 1-2 0a1 1 0 0 1 2 0Zm7 1a1 1 0 1 0 0-2a1 1 0 0 0 0 2Z"
								clipRule="evenodd"
							/>
						</svg>
						<span>More</span>
					</button>
					{moreLanguagesIsOpen && (
						<div className="languages">
							{languageList.slice(4).map((item: ILanguageItem) => (
								<LanguageItem
									key={item.id}
									id={item.id}
									image={item.image}
									label={item.label}
									activeLanguage={activeLanguage}
									toggle={setActiveLanguage}
									setMoreLanguagesIsOpen={setMoreLanguagesIsOpen}
								/>
							))}
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default LanguageList;
