/*eslint-disable */
import { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { Modal } from "bootstrap";
import { authService } from "../core/auth.service";
import { useAuth } from "../core/Auth";
import { IAuthModel, ILoginData } from "../core/auth.types";
import { useValidationSchema } from "../core/use-validation-schema.hook";
import { AdminLoginModal } from "./AdminLoginModal";

export function Login() {
  const { currentPasswordValidationSchema, emailValidationSchema } =
    useValidationSchema();
  const auth = useAuth();
  const intl = useIntl();

  const [loading, setLoading] = useState(false);
  const [loginData, setLoginData] = useState<IAuthModel | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const adminModal = useRef<Modal | null>(null);

  const icon = showPassword ? `fa fa-eye` : `fa fa-eye-slash`;
  const initialValues: ILoginData = {
    login: "",
    password: "",
  };

  const loginSchema = Yup.object().shape({
    login: emailValidationSchema,
    password: currentPasswordValidationSchema,
  });

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting, setErrors }) => {
      setLoading(true);

      try {
        const { data: loginData } = await authService.login(values);
        if (loginData.success) {
          setLoginData(loginData);
          if (loginData.redirect_url) {
            adminModal.current?.show();
            return;
          }

          await login(loginData);
        } else {
          if (loginData.errors.login) {
            setStatus();
            setErrors({
              login: loginData.errors.login,
            });
          } else {
            setStatus(loginData.errors.password);
          }
        }
      } catch (e) {
        setStatus(intl.formatMessage({ id: "AUTH.INVALID_CREDENTIALS" }));
      } finally {
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  async function login(loginData: IAuthModel) {
    adminModal.current?.hide();
    auth.saveAuth(loginData);

    const {
      data: { details: profile },
    } = await authService.getProfile();
    auth.setCurrentProfile(profile);
  }

  useEffect(() => {
    adminModal.current = new Modal(
      document.getElementById("admin-login") as Element,
      { backdrop: "static" }
    );
  }, []);

  return (
    <form className="form w-100" onSubmit={formik.handleSubmit}>
      <AdminLoginModal
        redirectUrl={loginData?.redirect_url as string}
        loading={loading}
        onLogin={() => login(loginData as IAuthModel)}
      />

      <div className="text-center mb-10">
        <h2 className="text-dark mb-3">
          {intl.formatMessage({ id: "AUTH.SIGN_IN" })}
        </h2>
      </div>

      {formik.status && (
        <div className="mb-xl-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      )}

      <div className="fv-row mb-10">
        <label className="form-label fs-6 fw-bolder text-dark is-required">
          {intl.formatMessage({ id: "AUTH.EMAIL" })}
        </label>
        <input
          placeholder={intl.formatMessage({ id: "AUTH.EMAIL" })}
          {...formik.getFieldProps("login")}
          className={clsx(
            "form-control form-control-lg form-control-solid",
            { "is-invalid": formik.touched.login && formik.errors.login },
            { "is-valid": formik.touched.login && !formik.errors.login }
          )}
          type="email"
          name="login"
          autoComplete="off"
        />
        {formik.touched.login && formik.errors.login && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.login}</span>
            </div>
          </div>
        )}
      </div>

      <div className="fv-row mb-10 position-relative">
        <div className="d-flex justify-content-between mt-n5">
          <div className="d-flex flex-stack mb-2">
            <label className="form-label fw-bolder text-dark fs-6 mb-0 is-required">
              {intl.formatMessage({ id: "AUTH.PASSWORD" })}
            </label>
            <Link
              to="/auth/forgot-password"
              className="link-primary fs-6 fw-bolder ms-2"
              style={{ marginLeft: "5px" }}
            >
              {intl.formatMessage({ id: "AUTH.FORGOT_PASSWORD" })}
            </Link>
          </div>
        </div>
        <input
          placeholder={intl.formatMessage({ id: "AUTH.PASSWORD" })}
          type={showPassword ? "text" : "password"}
          autoComplete="off"
          {...formik.getFieldProps("password")}
          className={clsx(
            "form-control form-control-lg form-control-solid",
            {
              "is-invalid-0": formik.touched.password && formik.errors.password,
            },
            { "is-valid-0": formik.touched.password && !formik.errors.password }
          )}
        />
        <span
          className={"toggle-password"}
          onClick={() => setShowPassword(!showPassword)}
        >
          <i className={icon}></i>
        </span>
        {formik.touched.password && formik.errors.password && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>

      <div className="text-center">
        <button
          type="submit"
          className="btn btn-lg btn-primary w-100"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && (
            <span className="indicator-label">
              {intl.formatMessage({ id: "BUTTONS.CONTINUE" })}
            </span>
          )}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              {intl.formatMessage({ id: "GLOBAL.PLEASE_WAIT" })}
              <span className="spinner-border spinner-border-sm align-middle ms-2" />
            </span>
          )}
        </button>
      </div>
    </form>
  );
}
