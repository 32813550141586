import { EDayPart, EMonth, EWeekDay } from '../../../types'

export enum ESalesTotal {
  Sales = 'Sales',
  Transactions = 'Transactions',
  Products = 'Products'
}

export enum EPeriodType {
  Month = 'Month',
  Weekday = 'Weekday',
  DayPart = 'DayPart'
}

export interface ISalesDataItem {
  count: number
  amount: number
}

export interface IRepeatVsOneTimeCustomers {
  one_time: number
  repeated: number
}

export interface ISalesInsights {
  availableYears: {
    [key: string]: number
  }
  currency: string
  totalSales: number
  totalTransactions: number
  soldProducts: number
  topSellingProducts: {
    [key: string]: ISalesDataItem
  }
  monthlySales: {
    [Month in keyof EMonth]: ISalesDataItem
  }
  weekdaySales: {
    [Weekday in keyof EWeekDay]: ISalesDataItem
  }
  daypartSales: {
    [Weekday in keyof EDayPart]: ISalesDataItem
  }
}

export interface ICustomersInsights {
  availableYears: {
    [key: string]: number
  }
  payment_types: {
    [key: string]: string
  }
  repeat_customers_one_time_customers: IRepeatVsOneTimeCustomers
  sales_per_payment_type: {
    [key: string]: ISalesDataItem
  }
  sales_per_products_quantity: {
    [key: string]: number
  }
  visits_number_per_customers: {
    [key: string]: ISalesDataItem
  }
  currency: string
}

export interface ITopProductsInsights {
  availableYears: {
    [key: string]: number
  }
  currency: string
  topSellingProducts: {
    [key: string]: ISalesDataItem
  }
}