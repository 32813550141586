import { FC, useEffect, useState } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { AppLoading } from '../../../../components/shared'
import { Filters } from '../../../../components/shared'
import { TopProducts } from '../../components'
import { useFilters } from '../../../../core/hooks'
import { insightsService } from '../../core/insights.service'
import { ITopProductsInsights } from '../../core/insights.types'
import { useToasts } from '../../../toasts/Toasts'
import { useIntl } from 'react-intl'

export const TopProductsInsights: FC = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [topProductsData, setTopProductsData] = useState<ITopProductsInsights | null>(null)
  const [filters, setFilters] = useFilters(true)

  const { showToast } = useToasts()

  const getTopProducts = async () => {
    setLoading(true)

    try {
      const { data: { data } } = await insightsService.getTopProducts(filters)

      setTopProductsData(data)
    } catch (err) {
      showToast()
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getTopProducts()
  }, [filters])

  return (
    <>
      <PageTitle>
        {intl.formatMessage({ id: 'TOP_PRODUCTS.TITLE' })}
      </PageTitle>

      { loading || !topProductsData
        ? <AppLoading />
        : (
          <>
            <Filters
              filters={filters}
              setFilters={setFilters}
            />

            <div className='row mt-6'>
              <div className='col-12'>
                <TopProducts
                  products={topProductsData.topSellingProducts}
                  currency={topProductsData.currency}
                />
              </div>
            </div>
          </>
        )
      }
    </>
  )
}