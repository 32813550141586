import { FC, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { KTCard, KTCardBody } from '../../../../../_metronic/helpers'
import { SimpleAreaChart, SimpleBarChart } from '../../../../components/charts'
import { useDataForSimpleChart } from '../../core/hooks/use-data-for-simple-chart.hook'
import { EPeriodType, ISalesDataItem } from '../../core/insights.types'
import { ReportTooltip } from './ReportTooltip'

type TProps = {
  type: EPeriodType
  data: {[key: string]: ISalesDataItem}
  totalAmount: number
  totalCount: number
  currency: string
}

export const PeriodReport: FC<TProps> = ({
  type,
  data,
  currency
}) => {
  const intl = useIntl()

  const title = useMemo(() => {
    return {
      [EPeriodType.Month]: intl.formatMessage({ id: 'SALES_INSIGHTS.MONTHLY_REPORT' }),
      [EPeriodType.Weekday]: intl.formatMessage({ id: 'SALES_INSIGHTS.WEEKDAYS_REPORT' }),
      [EPeriodType.DayPart]: intl.formatMessage({ id: 'SALES_INSIGHTS.DAY_PART_REPORT' })
    }[type]
  }, [type])

  const transformedData = useDataForSimpleChart(data, false, true, true)

  const barChart = (
    <SimpleBarChart
      data={transformedData}
      tooltip={<ReportTooltip currency={currency} />}
      firstValueName={intl.formatMessage({ id: 'GLOBAL.TOTAL_SALES' })}
      secondValueName={intl.formatMessage({ id: 'SALES_INSIGHTS.TOTAL_PURCHASES' })}
      formatToPercent
    />
  )

  const areaChart = (
    <SimpleAreaChart
      data={transformedData}
      tooltip={<ReportTooltip currency={currency} />}
      firstValueName={intl.formatMessage({ id: 'GLOBAL.TOTAL_SALES' })}
      secondValueName={intl.formatMessage({ id: 'SALES_INSIGHTS.TOTAL_PURCHASES' })}
      formatToPercent
    />
  )

  const chartByType = {
    [EPeriodType.Month]: barChart,
    [EPeriodType.Weekday]: barChart,
    [EPeriodType.DayPart]: areaChart
  }

  return (
    <KTCard className='h-100'>
      <KTCardBody>
        <h3 className='card-title'>{title}</h3>

        <div className='mt-5 d-flex flex-column justify-content-center'>
          {chartByType[type]}
        </div>
      </KTCardBody>
    </KTCard>
  )
}