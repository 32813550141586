/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, useEffect, useState } from "react";
import { ISurveyAnswer } from "../core/survey.types";
import { Chart } from "primereact/chart";

type TProps = {
  answer: ISurveyAnswer;
};

const MatrixSurveyAnswer: FC<TProps> = ({ answer }) => {
  const questionAnswers = answer.answers;
  const colums = answer.question.columns as Array<{
    value: string;
    text: string;
  }>;
  const rows = answer.question.rows as Array<{
    value: string;
    text: string;
  }>;

  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [dataLabels, setDataLabels] = useState([]);

  useEffect(() => {
    const lbls: any[] = [];
    const dLbls: any[] = [];
    const dt: any[] = [];
    const bgColor: any = [];

    rows.map((r) => {
      colums.map((c) => {
        let key = `${r.text}:${c.text}`;
        const val = questionAnswers[key];
        if (val) {
          lbls.push(key);
          key = `${r.text}:${c.text}: ${val}`;
          dLbls.push(key);
          dt.push(val);
          bgColor.push("#" + Math.floor(Math.random() * 16777215).toString(16));
        }
      });
    });

    const data = {
      labels: [...lbls],
      datasets: [
        {
          data: [...dt],
          backgroundColor: [...bgColor],
          hoverBackgroundColor: [...bgColor],
        },
      ],
    };
    const options = {
      cutout: "80%",
    };

    setChartData(data);
    setChartOptions(options);
    setDataLabels(dLbls as any);
  }, []);

  return (
    <>
      <div className="container">
        <div className="row py-6">
          <div className="col-sm-12">
            <h4 className="py-3">{answer.question.title}</h4>
          </div>
          <div className="col-sm-12 col-md-6">
            {dataLabels.map((r) => {
              return (
                <>
                  <p>{r}</p>
                </>
              );
            })}
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="card flex justify-content-center">
              <Chart
                type="polarArea"
                data={chartData}
                options={chartOptions}
                style={{ maxWidth: 350 }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MatrixSurveyAnswer;
