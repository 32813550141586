import { AxiosResponse } from 'axios'
import { axiosInstance } from '../../../core'
import { EMediaType, IResponse } from '../../../types'
import {
  IApiBannerLocationItem,
  IDefaultBannersData,
  IBannerLocationItem,
  IUpdateBannersData
} from './default-banners.types'

interface IDefaultBannersService {
  getDefaultBanners: () => Promise<IBannerLocationItem[]>
  transformBannerLocationsFromApi: (locations: IApiBannerLocationItem[]) => IBannerLocationItem[]
  transformBannerLocationForApi(location: IBannerLocationItem): IUpdateBannersData
  updateDefaultBanners(location: IBannerLocationItem): Promise<AxiosResponse<{ success: boolean }>>
}

class DefaultBannersService implements IDefaultBannersService {
  async getDefaultBanners(): Promise<IBannerLocationItem[]> {
    const res = await axiosInstance.get<IResponse<IDefaultBannersData>>('/v1/banners')

    return this.transformBannerLocationsFromApi(res.data.data.locations)
  }

  async updateDefaultBanners(location: IBannerLocationItem): Promise<AxiosResponse<{ success: boolean }>> {
    return axiosInstance.post<{ success: boolean }>
    ('/v1/banners/update', this.transformBannerLocationForApi(location))
  }

  transformBannerLocationsFromApi(locations: IApiBannerLocationItem[]): IBannerLocationItem[] {
    const enumTypeById: { [key: number]: EMediaType } = {
      0: EMediaType.None,
      1: EMediaType.Image,
      2: EMediaType.Video
    }

    return locations.map(l => {
      return {
        ...l,
        header: {
          image: l.header.image || '',
          image_action_url: l.header.image_action_url || '',
          video: l.header.video || '',
          type: enumTypeById[l.header.type] || EMediaType.None
        },
        footer: {
          image: l.footer.image || '',
          image_action_url: l.footer.image_action_url || '',
          video: l.footer.video || '',
          type: enumTypeById[l.footer.type] || EMediaType.None
        }
      }
    })
  }

  transformBannerLocationForApi(location: IBannerLocationItem): IUpdateBannersData {
    const typeIdByEnum: { [key: string]: number } = {
      [EMediaType.None]: 0,
      [EMediaType.Image]: 1,
      [EMediaType.Video]: 2
    }

    const defaultValues: IUpdateBannersData = {
      location_id: location.id,
      header_type: typeIdByEnum[location.header.type] || 0,
      footer_type: typeIdByEnum[location.footer.type] || 0,
      header_image: '',
      header_image_action_url: '',
      header_video: '',
      footer_image: '',
      footer_image_action_url: '',
      footer_video: ''
    }

    if (location.header.type === EMediaType.Image) {
      defaultValues.header_image = location.header.image
      defaultValues.header_image_action_url = location.header.image_action_url
    } else if (location.header.type === EMediaType.Video) {
      defaultValues.header_video = location.header.video
    }

    if (location.footer.type === EMediaType.Image) {
      defaultValues.footer_image = location.footer.image
      defaultValues.footer_image_action_url = location.footer.image_action_url
    } else if (location.footer.type === EMediaType.Video) {
      defaultValues.footer_video = location.footer.video
    }

    return defaultValues
  }
}

export const defaultBannersService = new DefaultBannersService()