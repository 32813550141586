import clsx from 'clsx'
import { useFormik } from 'formik'
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components'
import { useToasts } from '../../toasts/Toasts'
import { AuthLayout } from '../AuthPage'
import { useAuth } from '../core/Auth'
import { authService } from '../core/auth.service'
import { IRecoverPassword } from '../core/auth.types'
import { useValidationSchema } from '../core/use-validation-schema.hook'

const RecoverPasswordPage: FC = () => {
  const intl = useIntl()
  const params = useParams()
  const { auth } = useAuth()
  const { showToast } = useToasts()
  const navigate = useNavigate()
  const { newPasswordValidationSchema, confirmPasswordValidationSchema } = useValidationSchema()

  const [loading, setLoading] = useState<boolean>(false)

  const initialValues = {
    password: '',
    confirmPassword: ''
  }

  const recoverySchema = Yup.object().shape({
    password: newPasswordValidationSchema,
    confirmPassword: confirmPasswordValidationSchema
  })

  const formik = useFormik({
    initialValues,
    validationSchema: recoverySchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setSubmitting(true)

      const data: IRecoverPassword = {
        code: params.code || '',
        id: params.id || '',
        password: values.password
      }

      try {
        await authService.recoverPassword(data)

        showToast({ bg: 'success', text: 'Password has been changed!' })
        navigate('/auth/login')
      } catch {
        setStatus(intl.formatMessage({ id: 'GLOBAL.SOMETHING_WENT_WRONG' }))
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    }
  })

  useEffect(() => {
    if (auth) {
      navigate('/dashboard')
      return
    } else if (!params.code || !params.id) {
      navigate('/login')
    }

    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <AuthLayout>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        onSubmit={formik.handleSubmit}
      >
        <div className='mb-10 text-center'>
          <h2 className='text-dark mb-3'>{intl.formatMessage({ id: 'AUTH.RECOVER_PASSWORD' })}</h2>
        </div>

        {formik.status && (
          <div className='mb-xl-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}

        <div className='fv-row mb-10' data-kt-password-meter='true'>
          <div className='mb-1'>
            <label className='form-label fw-bolder text-dark fs-6'>
              {intl.formatMessage({ id: 'AUTH.NEW_PASSWORD' })}
            </label>
            <div className='position-relative mb-3'>
              <input
                type='password'
                placeholder={intl.formatMessage({ id: 'AUTH.PASSWORD_CONFIRMATION' })}
                autoComplete='off'
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.password && formik.errors.password
                  },
                  {
                    'is-valid': formik.touched.password && !formik.errors.password
                  }
                )}
              />
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>
            <div
              className='d-flex align-items-center mb-3'
              data-kt-password-meter-control='highlight'
            >
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2' />
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2' />
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2' />
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px' />
            </div>
          </div>
          <div className='text-muted'>
            {intl.formatMessage({ id: 'AUTH.NEW_PASSWORD_HINT' })}
          </div>
        </div>

        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-dark fs-6'>
            {intl.formatMessage({ id: 'AUTH.CONFIRM_NEW_PASSWORD' })}
          </label>
          <input
            type='password'
            placeholder={intl.formatMessage({ id: 'AUTH.CONFIRM_NEW_PASSWORD' })}
            autoComplete='off'
            {...formik.getFieldProps('confirmPassword')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword
              },
              {
                'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword
              }
            )}
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.confirmPassword}</span>
              </div>
            </div>
          )}
        </div>

        <div className='text-center'>
          <button
            type='submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>
              {intl.formatMessage({ id: 'BUTTONS.SUBMIT' })}
            </span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                {intl.formatMessage({ id: 'GLOBAL.PLEASE_WAIT' })} {' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2' />
              </span>
            )}
          </button>
        </div>
      </form>
    </AuthLayout>
  )
}

export default RecoverPasswordPage