import { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { MenuComponent } from '../../../../_metronic/assets/ts/components'
import { ILocationFilterItem } from '../core/dashboard.types'
import { KTSVG } from '../../../../_metronic/helpers'

type TProps = {
  locationFilter: ILocationFilterItem[]
  disabled: boolean
  setLocationFilter: Dispatch<SetStateAction<ILocationFilterItem[]>>
}

export const LocationFilter: FC<TProps> = ({ locationFilter, disabled, setLocationFilter }) => {
  const intl = useIntl()
  const [localLocations, setLocalLocations] = useState<ILocationFilterItem[]>(locationFilter)
  const [search, setSearch] = useState('')

  const filteredLocations = useMemo<ILocationFilterItem[]>(() => {
    return localLocations.filter(l => l.name.toLowerCase().includes(search.toLowerCase()))
  }, [search, localLocations])

  useEffect(() => {
    MenuComponent.createInstance('#location-select-menu')
  }, [])

  useEffect(() => {
    setLocalLocations(locationFilter)
  }, [locationFilter])

  const updateLocation = (location: ILocationFilterItem, enabled: boolean) => {
    setLocalLocations(localLocations.map(b => {
      if (b.id !== location.id) return b

      return { ...b, enabled }
    }))
  }
  const apply = () => {
    setLocationFilter(localLocations)
  }
  const reset = () => {
    const allEnabled = localLocations.map(b => ({ ...b, enabled: true }))
    setLocalLocations(allEnabled)
    setLocationFilter(allEnabled)
    setSearch('')
  }

  return <>
    <button
      className='btn btn-success'
      data-kt-menu-trigger='click'
      data-kt-menu-placement='bottom-end'
      data-kt-menu-flip='top-end'
      disabled={disabled}
    >
      {intl.formatMessage({ id: 'LABELS.SELECT_LOCATIONS' })}
    </button>

    <div
      className='menu menu-sub menu-sub-dropdown py-5'
      data-kt-menu='true'
      id='location-select-menu'
    >
      <div className='px-5 position-relative'>
        <input
          type='text'
          className='form-control bg-transparent ps-13 h-40px'
          placeholder={intl.formatMessage({ id: 'LABELS.SEARCH' })}
          value={search}
          onInput={(e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
        />

        <div className='position-absolute top-50 right-0 translate-middle-y' style={{ left: '28px' }}>
          <KTSVG
            path='/media/icons/duotune/general/gen004.svg'
            className='svg-icon svg-icon-2 svg-icon-gray-700'
          />
        </div>
      </div>

      <div
        className='d-flex flex-column mt-5 me-2 ps-5'
        style={{ maxHeight: '50vh', overflow: 'auto' }}
      >
        {
          filteredLocations.length
            ? filteredLocations.map(location => (
              <div
                key={location.name}
                className='form-check form-switch form-switch-sm form-check-solid form-check-custom py-2'
                onClick={() => updateLocation(location, !location.enabled)}
              >
                <input
                  className='form-check-input'
                  type='checkbox'
                  name={location.name}
                  checked={location.enabled}
                  onChange={() => updateLocation(location, !location.enabled)}
                />
                <label className='form-check-label fs-5 ms-3'>{location.name}</label>
              </div>
            ))
            : <span className='fs-5 fw-bold'>
              {intl.formatMessage({ id: 'GLOBAL.NO_LOCATIONS_FOUND' })}
            </span>
        }
      </div>

      <div className='d-flex align-items-center justify-content-end mt-5 px-5'>
        <button
          className='btn btn-sm btn-success btn-light mx-2'
          data-kt-menu-dismiss='true'
          onClick={reset}
        >
          {intl.formatMessage({ id: 'BUTTONS.SELECT_ALL' })}
        </button>
        <button
          className='btn btn-sm btn-primary'
          data-kt-menu-dismiss='true'
          onClick={apply}
        >
          {intl.formatMessage({ id: 'BUTTONS.APPLY' })}
        </button>
      </div>
    </div>
  </>
}