import { FC } from 'react'
import { useIntl } from 'react-intl'
import { KTCard, KTCardBody } from '../../../../../_metronic/helpers'
import { SimpleBarChart } from '../../../../components/charts'
import { useDataForSimpleChart } from '../../core/hooks/use-data-for-simple-chart.hook'
import { ReportTooltip } from '../sales-insights/ReportTooltip'

type TProps = {
  data: {[key: string]: number}
  currency: string
}

export const SalesPerProductsQty: FC<TProps> = ({ data, currency = '$' }) => {
  const intl = useIntl()
  const transformedData = useDataForSimpleChart(data, true, false)

  return (
    <KTCard className='h-100'>
      <KTCardBody>
        <h3 className='card-title'>{intl.formatMessage({ id: 'CUSTOMERS_INSIGHTS.SALES_PER_PRODUCTS_QTY' })}</h3>

        <SimpleBarChart
          data={transformedData}
          tooltip={<ReportTooltip currency={currency} />}
          showLegend={false}
          className='mt-15'
          formatToPercent
        />
      </KTCardBody>
    </KTCard>
  )
}