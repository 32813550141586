/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC } from "react";
import { ISurveyAnswer } from "../core/survey.types";

type TProps = {
  answer: ISurveyAnswer;
};

const SignaturePadAnswer: FC<TProps> = ({ answer }) => {
  const answers = answer.answers as Array<string>;

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h4 className="py-3">{answer.question.title}</h4>
          </div>
          <div className="col-sm-12">
            <div className="justify-content-center">
              <div className="row py-3">
                {answers.map((ans) => {
                  return (
                    <>
                      <div className="col">
                        <div className="card d-flex" style={{ width: "18rem" }}>
                          <img
                            className="card-img-top survey-image m-auto"
                            src={ans}
                          />
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignaturePadAnswer;
