/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC } from "react";
import { ISurveyData } from "../core/survey.types";
import DropdownSurveyAnswer from "./DropdownSurveyAnswer";
import CommentSurveyAnswer from "./CommentSurveyAnswer";
import RatingSurveyAnswer from "./RatingSurveyAnswer";
import BooleanSurveyAnswer from "./BoolenSurveyAnswer";
import FileSurveyAnswer from "./FileSurveyAnswer";
import RankingSurveyAnswer from "./RankingSurveyAnswer";
import MatrixSurveyAnswer from "./MatrixSurveyAnswer";
import MatrixDropDownSurveyAnswer from "./MatrixDropDownSurveyAnswer";
import SignaturePadAnswer from "./SignaturePadAnswer";

type TProps = {
  survey: ISurveyData;
};

const SurveyDetail: FC<TProps> = ({ survey }) => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 py-6">
            <h2>{survey.title}</h2>
          </div>
          {survey.answers &&
            survey.answers.map((item, i) => {
              switch (item.question.type) {
                case "dropdown":
                case "checkbox":
                  return (
                    <DropdownSurveyAnswer
                      key={item.question.id}
                      answer={item}
                    />
                  );

                case "comment":
                case "text":
                  return (
                    <CommentSurveyAnswer key={item.question.id} answer={item} />
                  );
                case "rating":
                  return (
                    <RatingSurveyAnswer key={item.question.id} answer={item} />
                  );
                case "boolean":
                  return (
                    <BooleanSurveyAnswer key={item.question.id} answer={item} />
                  );
                case "file":
                  return (
                    <FileSurveyAnswer key={item.question.id} answer={item} />
                  );
                case "ranking":
                  return (
                    <RankingSurveyAnswer key={item.question.id} answer={item} />
                  );
                case "matrix":
                  // case "matrixdynamic":
                  return (
                    <MatrixSurveyAnswer key={item.question.id} answer={item} />
                  );
                case "matrixdropdown":
                case "matrixdynamic":
                  return (
                    <MatrixDropDownSurveyAnswer
                      key={item.question.id}
                      answer={item}
                    />
                  );
                case "signaturepad":
                  return (
                    <SignaturePadAnswer key={item.question.id} answer={item} />
                  );
              }
            })}
        </div>
      </div>
    </>
  );
};

export default SurveyDetail;
