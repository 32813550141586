import { FC, ReactElement, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts'
import { ISimpleChartDataItem } from '../../types'
import { usePercentage } from './core/hooks/use-percentage.hook'

type TProps = {
  data: ISimpleChartDataItem[]
  tooltip?: ReactElement
  firstValueName?: string
  secondValueName?: string
  formatToPercent?: boolean
  showLegend?: boolean
  className?: string
  xTickFormatter?: (v: any, i: number) => string
}

export const SimpleAreaChart: FC<TProps> = ({
  data,
  tooltip,
  firstValueName,
  secondValueName,
  formatToPercent,
  className,
  showLegend = true,
  xTickFormatter = v => v
}) => {
  const intl = useIntl()
  const finalData = useMemo<ISimpleChartDataItem[]>(() => {
    if (!formatToPercent) return data

    return usePercentage(data)
  }, [data])

  const isEmpty = useMemo<boolean>(() => {
    return !finalData.length || finalData.every((d) => !d.firstValue && !d.secondValue)
  }, [finalData])

  return (
    <>
      {
        isEmpty
          ? <div className='fs-3x text-gray-500 fw-bold'>{intl.formatMessage({ id: 'GLOBAL.NO_DATA' })}</div>
          : (
            <div style={{ height: '280px' }} className={className}>
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  width={500}
                  height={300}
                  data={finalData}
                  margin={{
                    top: 5,
                    right: 10,
                    bottom: 5,
                    left: -20
                  }}>
                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#009ef7" stopOpacity={0.8}/>
                      <stop offset="95%" stopColor="#009ef7" stopOpacity={0}/>
                    </linearGradient>
                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#50cd89" stopOpacity={0.8}/>
                      <stop offset="95%" stopColor="#50cd89" stopOpacity={0}/>
                    </linearGradient>
                  </defs>
                  <XAxis dataKey="name" tickFormatter={xTickFormatter} />
                  <YAxis unit={formatToPercent ? '%' : ''} />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip content={tooltip} />
                  {showLegend && <Legend />}
                  <Area
                    name={firstValueName}
                    type="monotone"
                    dataKey="firstValue"
                    stroke="#009ef7"
                    fillOpacity={1}
                    fill="url(#colorUv)"
                  />
                  { data.some(d => d.secondValue) &&
                <Area
                  name={secondValueName}
                  type="monotone"
                  dataKey="secondValue"
                  stroke="#50cd89"
                  fillOpacity={1}
                  fill="url(#colorPv)"
                />
                  }
                </AreaChart>
              </ResponsiveContainer>
            </div>
          )
      }
    </>
  )
}