import { FC, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { KTCard, KTCardBody } from '../../../../../_metronic/helpers'
import { ActiveShapePieChart } from '../../../../components/charts'
import { IPieChartDataItem } from '../../../../types'
import { IRepeatVsOneTimeCustomers } from '../../core/insights.types'
''
type TProps = {
  data: IRepeatVsOneTimeCustomers
}

export const RepeatVsOneTime: FC<TProps> = ({ data }) => {
  const intl = useIntl()

  const transformedData = useMemo<IPieChartDataItem[]>(() => {
    return [
      {
        name: intl.formatMessage({ id: 'CUSTOMERS_INSIGHTS.REPEAT' }),
        value: data.repeated,
        fill: '#50cd89'
      },
      {
        name: intl.formatMessage({ id: 'CUSTOMERS_INSIGHTS.ONE-TIME' }),
        value: data.one_time,
        fill: '#009ef7'
      }
    ]
  }, [data])

  return (
    <KTCard>
      <KTCardBody>
        <h3 className='card-title'>{intl.formatMessage({ id: 'CUSTOMERS_INSIGHTS.REPEAT_VS_ONE_TIME' })}</h3>

        <ActiveShapePieChart data={transformedData} withLegend />
      </KTCardBody>
    </KTCard>
  )
}