import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { ISimpleChartDataItem } from '../../../../types'
import { ISalesDataItem } from '../insights.types'

export const useDataForSimpleChart = (
  data: {[key: string]: ISalesDataItem | number},
  plainNumber = false,
  useCount = true,
  translateName = false
): ISimpleChartDataItem[] => {
  const intl = useIntl()

  return useMemo(() => {
    return Object.entries(data).map(([name, item]) => {
      const output: ISimpleChartDataItem = {
        name: translateName ? intl.formatMessage({ id: `CHART_LABELS.${name}` }) : name,
        firstValue: (plainNumber ? (item as number) : (item as ISalesDataItem).amount)
      }

      if (useCount) {
        output.secondValue = (plainNumber ? (item as number) : (item as ISalesDataItem).count)
      }

      return output
    })
  }, [data, plainNumber, useCount])
}