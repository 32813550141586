import { FC, useEffect, useMemo, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { ImageInputComponent } from '../../../_metronic/assets/ts/components'
import { KTCard, KTCardBody } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import { ImageUpload, LocationSelect, MediaTypeSelect, PreviewModal, VideoUpload } from './components'
import { IBannerLocationItem } from './core/default-banners.types'
import { defaultBannersService } from './core/default-banners.service'
import { useNavigate } from 'react-router-dom'
import { AppLoading } from '../../components/shared'
import { EMediaType } from '../../types'
import { useToasts } from '../toasts/Toasts'
import { useIntl } from 'react-intl'

const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/

export const DefaultBannersPage: FC = () => {
  const intl = useIntl()
  const navigate = useNavigate()

  const [headerMediaType, setHeaderMediaType] = useState<EMediaType>(EMediaType.None)
  const [footerMediaType, setFooterMediaType] = useState<EMediaType>(EMediaType.None)
  const [currentBannerLocation, setCurrentBannerLocation] = useState<IBannerLocationItem>()
  const [locations, setLocations] = useState<IBannerLocationItem[]>([])

  const [initialLoading, setInitialLoading] = useState(true)
  const [formLoading, setFormLoading] = useState(false)
  const { showToast } = useToasts()

  // const [previewLoading, setPreviewLoading] = useState(false)

  const getImgSchema = () => Yup.object().shape({
    image: Yup.string().required(intl.formatMessage(
      { id: 'VALIDATION.REQUIRED' },
      { name: intl.formatMessage({ id: 'DEFAULT_BANNERS.IMAGE' }) }
    )),
    image_action_url: Yup.string().matches(urlRegex, intl.formatMessage(
      { id: 'VALIDATION.WRONG_FORMAT' },
      { name: intl.formatMessage({ id: 'GLOBAL.URL' }) }
    ))
  })
  const getVideoSchema = () => Yup.object().shape({
    video: Yup.string()
      .required(intl.formatMessage(
        { id: 'VALIDATION.REQUIRED' },
        { name: intl.formatMessage({ id: 'DEFAULT_BANNERS.YOUTUBE_VIDEO_ID' }) }
      ))
      .length(11, intl.formatMessage(
        { id: 'VALIDATION.INVALID' },
        { name: intl.formatMessage({ id: 'DEFAULT_BANNERS.YOUTUBE_VIDEO_ID' }) }
      ))
  })

  const schemaByType = {
    [EMediaType.Image]: getImgSchema,
    [EMediaType.Video]: getVideoSchema,
    [EMediaType.None]: Yup.object
  }

  const currentHeaderSchema = useMemo<Yup.AnyObjectSchema>(() => {
    return schemaByType[headerMediaType]()
  }, [headerMediaType])
  const currentFooterSchema = useMemo<Yup.AnyObjectSchema>(() => {
    return schemaByType[footerMediaType]()
  }, [footerMediaType])

  const formik = useFormik({
    initialValues: {
      header: { image: '', image_action_url: '', video: '' },
      footer: { image: '', image_action_url: '', video: '' }
    },
    validationSchema: Yup.object().shape({
      header: currentHeaderSchema,
      footer: currentFooterSchema
    }),
    onSubmit: async (values, { setSubmitting }) => {
      if (!currentBannerLocation) return

      setSubmitting(true)
      setFormLoading(true)

      try {
        const updatedLocation = structuredClone({
          ...currentBannerLocation,
          header: {
            ...values.header,
            type: headerMediaType
          },
          footer: {
            ...values.footer,
            type: footerMediaType
          }
        })

        setCurrentBannerLocation(updatedLocation)
        setLocations(prev => prev.map(l => l.id === updatedLocation.id ? updatedLocation : l))

        const { data } = await defaultBannersService.updateDefaultBanners(updatedLocation)

        if (data.success) {
          showToast({ text: intl.formatMessage({ id: 'TOAST.SAVED' }), bg: 'success' })
        }
      } catch (err) {
        showToast()
      } finally {
        setSubmitting(false)
        setFormLoading(false)
      }
    }
  })

  const getDefaultBanners = async () => {
    try {
      const banners = await defaultBannersService.getDefaultBanners()

      setLocations(banners)
      setCurrentBannerLocation(structuredClone(banners[0]))
    } catch (err) {
      showToast()
    } finally {
      setInitialLoading(false)
    }
  }

  useEffect(() => {
    if (!currentBannerLocation) return

    formik.resetForm()

    const {
      header: { type: headerType, ...headerData },
      footer: { type: footerType, ...footerData }
    } = currentBannerLocation

    setHeaderMediaType(headerType)
    setFooterMediaType(footerType)

    formik.initialValues.header = headerData
    formik.initialValues.footer = footerData
  }, [currentBannerLocation])

  useEffect(() => {
    ImageInputComponent.bootstrap()
    getDefaultBanners()
  }, [])

  const headerImageUpload = (
    <ImageUpload
      handleChange={v => formik.setFieldValue('header.image', v)}
      data={formik.values.header}
      imgProps={formik.getFieldProps('header.image')}
      imgActionProps={formik.getFieldProps('header.image_action_url')}
      touched={formik.touched.header}
      errors={formik.errors.header}
    />
  )
  const footerImageUpload = (
    <ImageUpload
      handleChange={v => formik.setFieldValue('footer.image', v)}
      data={formik.values.footer}
      imgProps={formik.getFieldProps('footer.image')}
      imgActionProps={formik.getFieldProps('footer.image_action_url')}
      touched={formik.touched.footer}
      errors={formik.errors.footer}
    />
  )

  const headerVideoUpload = (
    <VideoUpload
      data={formik.values.header}
      props={formik.getFieldProps('header.video')}
      errors={formik.errors.header}
      touched={formik.touched.header}
    />
  )
  const footerVideoUpload = (
    <VideoUpload
      data={formik.values.footer}
      props={formik.getFieldProps('footer.video')}
      errors={formik.errors.footer}
      touched={formik.touched.footer}
    />
  )

  const noUpload = <div className='fs-4x text-gray-700'>-</div>

  const headerUploadByType = {
    [EMediaType.Image]: headerImageUpload,
    [EMediaType.Video]: headerVideoUpload,
    [EMediaType.None]: noUpload
  }
  const footerUploadByType = {
    [EMediaType.Image]: footerImageUpload,
    [EMediaType.Video]: footerVideoUpload,
    [EMediaType.None]: noUpload
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'MENU.DEFAULT_BANNERS' })}
      </PageTitle>

      <PreviewModal
        header={{ ...formik.values.header, type: headerMediaType }}
        footer={{ ...formik.values.footer, type: footerMediaType }}
      />

      {!initialLoading && locations.length > 1 && (
        <div className='col-12 mb-10'>
          <label className='form-label fs-2 fw-bolder'>
            {intl.formatMessage({ id: 'LABELS.SELECT_A_LOCATION' })}
          </label>
          <LocationSelect
            value={(currentBannerLocation as IBannerLocationItem).id}
            setValue={id => setCurrentBannerLocation(locations.find((l) => l.id === id))}
          />
        </div>
      )}

      <KTCard>
        <KTCardBody>
          {initialLoading
            ? <AppLoading className='h-500px' />
            : (
              <form onSubmit={formik.handleSubmit}>
                <div className='row'>
                  <div className='col-12 d-flex flex-column'>
                    <MediaTypeSelect
                      label={intl.formatMessage({ id: 'DEFAULT_BANNERS.RECEIPT_HEADER' })}
                      value={headerMediaType}
                      setValue={setHeaderMediaType}
                    />

                    <div className='mt-5'>
                      {headerUploadByType[headerMediaType]}
                    </div>
                  </div>
                  <div className='col-12 mt-10 pt-10 border-top border-gray-300'>
                    <MediaTypeSelect
                      label={intl.formatMessage({ id: 'DEFAULT_BANNERS.RECEIPT_FOOTER' })}
                      value={footerMediaType}
                      setValue={setFooterMediaType}
                    />

                    <div className='mt-5'>
                      {footerUploadByType[footerMediaType]}
                    </div>
                  </div>
                </div>

                <div className='d-flex align-items-center justify-content-end mt-10'>
                  <button
                    className='btn btn-sm btn-active-light btn-color-muted'
                    type='button'
                    onClick={() => navigate('/dashboard')}
                  >
                    {intl.formatMessage({ id: 'BUTTONS.CANCEL' })}
                  </button>

                  <button
                    className='btn btn-sm btn-light-primary ms-2'
                    data-bs-toggle='modal'
                    data-bs-target='#modal-banners-preview'
                    type='button'
                  >
                    {intl.formatMessage({ id: 'DEFAULT_BANNERS.PREVIEW' })}
                  </button>

                  <button
                    type='submit'
                    className='btn btn-sm btn-primary ms-2'
                    disabled={formik.isSubmitting}
                  >
                    {!formLoading && <span className='indicator-label'>
                      {intl.formatMessage({ id: 'BUTTONS.SAVE' })}
                    </span>}
                    {formLoading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        {intl.formatMessage({ id: 'GLOBAL.PLEASE_WAIT' })}
                        <span className='spinner-border spinner-border-sm align-middle ms-2' />
                      </span>
                    )}
                  </button>
                </div>
              </form>
            )
          }
        </KTCardBody>
      </KTCard>
    </>
  )
}