import { FC } from 'react'
import { useIntl } from 'react-intl'
import { KTCard, KTCardBody } from '../../../../_metronic/helpers'

type TProps = {
  value: string
}

export const CurrentCredit: FC<TProps> = ({ value }) => {
  const intl = useIntl()

  return <KTCard>
    <KTCardBody>
      <h3 className='text-gray-800 fs-1'>
        {intl.formatMessage({ id: 'DASHBOARD.CURRENT_CREDIT' })}
      </h3>

      <span className='text-primary fs-3x fw-bold fst-italic'>
        {value || 0}
      </span>
    </KTCardBody>
  </KTCard>
}