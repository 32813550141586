import { AxiosResponse } from 'axios'
import { axiosInstance } from '../../../core'
import {
  IAuthModel,
  IChangePasswordData,
  ILoginData,
  IProfileResponse,
  IRecoverPassword,
  IRegisterData
} from './auth.types'

interface IAuthService {
  login: (data: ILoginData) => Promise<AxiosResponse<IAuthModel>>
  register: (data: IRegisterData) => Promise<AxiosResponse<IAuthModel>>
  getProfile: () => Promise<AxiosResponse<IProfileResponse>>
  changePassword: (data: IChangePasswordData) => Promise<AxiosResponse<{success: boolean}>>
  resetPassword: (email: string) => Promise<AxiosResponse<{success: boolean}>>
  recoverPassword: (d: IRecoverPassword) => Promise<any>
}

class AuthService implements IAuthService {
  login(data: ILoginData) {
    return axiosInstance.post<IAuthModel>('/security/login', data)
  }

  register(data: IRegisterData) {
    return axiosInstance.post<IAuthModel>('/register', data)
  }

  changePassword(data: IChangePasswordData) {
    return axiosInstance.post<any>("/security/change-password", data);
  }

  resetPassword(email: string) {
    return axiosInstance.post('/security/recovery', { email })
  }

  recoverPassword(data: IRecoverPassword) {
    return axiosInstance.post('/security/recovery/change-pasword', data)
  }

  getProfile() {
    return axiosInstance.get<IProfileResponse>('/security/profile')
  }
}

export const authService = new AuthService()