import { FC } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { CustomersInsights } from './pages/customer-insights/CustomersInsights'
import { SalesInsights } from './pages/sales-insights/SalesInsights'
import { TopProductsInsights } from './pages/top-products-insights/TopProductsInsights'

export const InsightsPage: FC = () => {
  return (
    <Routes>
      <Route path='sales' element={<SalesInsights />} />
      <Route path='customers' element={<CustomersInsights />} />
      <Route path='top-products' element={<TopProductsInsights />} />

      <Route path='*' element={<Navigate to='sales' />} />
      <Route index element={<Navigate to='sales' />} />
    </Routes>
  )
}