import { Component, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const ConfirmLogout = (props: any) => {
  const handleLogout = (resp: boolean) => {
    props.logoutUser(resp);
  };

  return (
    <>
      <Modal show={true} onHide={() => handleLogout(false)} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Logout!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleLogout(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => handleLogout(true)}>
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfirmLogout;
