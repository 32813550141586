import { FC, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { EMonth } from '../../types'

type TProps = {
  value: string | null
  setValue: (value: string | null) => void
}

const monthNames = Object.values(EMonth)

export const MonthSelect: FC<TProps> = ({ value, setValue }) => {
  const intl = useIntl()

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    $('#month-select').select2({
      language: {
        noResults: () => intl.formatMessage({ id: 'GLOBAL.NO_RESULTS' })
      }
    })

    $('#month-select').on('select2:select', (e) => {
      const { value } = e.target as HTMLSelectElement

      if (value === 'All') {
        setValue(null)
        return
      }

      setValue(value)
    })
  }, [])

  return (
    <select
      name='month'
      id='month-select'
      aria-label={intl.formatMessage({ id: 'LABELS.SELECT_A_MONTH' })}
      className='form-select form-select-sm w-200px'
      data-placeholder={intl.formatMessage({ id: 'LABELS.SELECT_A_MONTH' })}
      defaultValue={value?.toString() || 'All'}
    >
      <option value='All'>
        {intl.formatMessage({ id: 'GLOBAL.ALL' })}
      </option>
      {
        monthNames.map((month) => {
          return (
            <option key={month} value={month}>
              {intl.formatMessage({ id: `CHART_LABELS.${month}` })}
            </option>
          )
        })
      }
    </select>
  )
}