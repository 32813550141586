import clsx from 'clsx'
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { ESalesTotal } from '../../core/insights.types'

type TProps = {
  type: ESalesTotal
  value: string | number
}

const titleByType = {
  [ESalesTotal.Sales]: 'GLOBAL.TOTAL_SALES',
  [ESalesTotal.Transactions]: 'SALES_INSIGHTS.TOTAL_TRANSACTIONS',
  [ESalesTotal.Products]: 'SALES_INSIGHTS.SOLD_PRODUCTS'
}

const iconByType = {
  [ESalesTotal.Sales]: 'fa-solid fa-hand-holding-dollar',
  [ESalesTotal.Transactions]: 'fa-solid fa-file-export',
  [ESalesTotal.Products]: 'fa-solid fa-cart-shopping'
}

const bgByType = {
  [ESalesTotal.Sales]: 'bg-primary',
  [ESalesTotal.Transactions]: 'bg-success',
  [ESalesTotal.Products]: 'bg-warning'
}

export const TotalBanner: FC<TProps> = ({ type, value }) => {
  const intl = useIntl()

  return (
    <div
      className={clsx(
        'd-flex justify-content-between align-items-center text-white p-4 rounded',
        bgByType[type]
      )}
    >
      <div className="d-flex flex-column justify-content-center overflow-hidden pe-2">
        <strong className='fs-4 fs-lg-3 text-white uppercase mb-0 text-truncate'>
          {intl.formatMessage({ id: titleByType[type] })}
        </strong>

        <strong className='fs-1'>{value}</strong>
      </div>

      <div>
        <i className={clsx('text-white fs-3x', iconByType[type])} />
      </div>
    </div>
  )
}