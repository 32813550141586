import { FC } from 'react'
import { KTCard, KTCardBody } from '../../../../../_metronic/helpers'
import { SimpleAreaChart } from '../../../../components/charts'
import { ISalesDataItem } from '../../core/insights.types'
import { useDataForSimpleChart } from '../../core/hooks/use-data-for-simple-chart.hook'
import { ReportTooltip } from '../sales-insights/ReportTooltip'
import { useIntl } from 'react-intl'

type TProps = {
  data: { [key: string]: ISalesDataItem }
  currency: string
}

export const VisitNumberPerCustomers: FC<TProps> = ({ data, currency = '$' }) => {
  const intl = useIntl()
  const transformedData = useDataForSimpleChart(data, false, false)

  const xTickFormatter = (v: any) => {
    v = `${v}`
    return v.length > 5 ? v.slice(0, 5) + '...' : v
  }

  return (
    <KTCard>
      <KTCardBody>
        <h3 className='card-title'>
          {intl.formatMessage({ id: 'CUSTOMERS_INSIGHTS.VISIT_NUMBER_PER_CUSTOMERS' })}
        </h3>

        <SimpleAreaChart
          data={transformedData}
          tooltip={<ReportTooltip currency={currency} />}
          showLegend={false}
          xTickFormatter={xTickFormatter}
          className='mt-5'
          formatToPercent
        />
      </KTCardBody>
    </KTCard>
  )
}