interface IPreviewService {
  getItem: (id: string) => Promise<unknown>
}

class PreviewService implements IPreviewService {
  getItem(id: string) {
    return new Promise(resolve => {
      setTimeout(resolve, 2000)
    })
  }
}

export const previewService = new PreviewService()