import { useEffect, useState } from "react";
import LanguageList from "./list/LanguageList";
import LanguageCodeBlock from "./LanguageCodeBlock";
import { LanguageRequestsHelper } from "../helpers";

const LanguageRequests = () => {
	const [activeLanguage, setActiveLanguage] = useState("javascript");
	const [codeBlock, setCodeBlock] = useState<any>("");

	useEffect(() => {
		setCodeBlock(LanguageRequestsHelper.getCodeById(activeLanguage));
	}, [activeLanguage]);

	return (
		<>
			<LanguageList
				activeLanguage={activeLanguage}
				setActiveLanguage={setActiveLanguage}
			/>

			<LanguageCodeBlock
				language={activeLanguage}
				code={codeBlock}
				showLineNumbers={true}
			/>
		</>
	);
};

export default LanguageRequests;
