import { FC, useEffect, useMemo, useState } from "react";
import { PageTitle } from "../../../../../_metronic/layout/core";
import { AppLoading } from "../../../../components/shared";
import { currencyParser } from "../../../../core/helpers";
import { Filters } from "../../../../components/shared";
import { insightsService } from "../../core/insights.service";
import { useFilters } from "../../../../core/hooks";
import {
	EPeriodType,
	ESalesTotal,
	ISalesInsights,
} from "../../core/insights.types";
import { PeriodReport } from "./PeriodReport";
import { TopProducts } from "../../components";
import { TotalBanner } from "./TotalBanner";
import { useToasts } from "../../../toasts/Toasts";
import { useIntl } from "react-intl";

export const SalesInsights: FC = () => {
	const intl = useIntl();

	const [loading, setLoading] = useState(true);
	const [salesData, setSalesData] = useState<ISalesInsights | null>(null);
	const [filters, setFilters] = useFilters();

	const totalBanners = useMemo<
		{ type: ESalesTotal; value: string | number }[]
	>(() => {
		if (!salesData) return [];

		const { totalSales, currency, totalTransactions, soldProducts } = salesData;
		console.log(currency);

		return [
			{
				type: ESalesTotal.Sales,
				value: currencyParser(totalSales, currency),
			},
			{ type: ESalesTotal.Transactions, value: totalTransactions },
			{ type: ESalesTotal.Products, value: soldProducts },
		];
	}, [salesData]);

	const { showToast } = useToasts();

	const getSales = async () => {
		setLoading(true);

		try {
			const {
				data: { data },
			} = await insightsService.getSales(filters);
			setSalesData(data);
		} catch (err) {
			showToast();
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getSales();
	}, [filters]);

	return (
		<>
			<PageTitle>{intl.formatMessage({ id: "MENU.INSIGHTS.SALES" })}</PageTitle>

			{loading || !salesData ? (
				<AppLoading className="h-500px" />
			) : (
				<>
					<Filters
						filters={filters}
						setFilters={setFilters}
						availableYears={Object.keys(salesData.availableYears)}
					/>

					<div className="row mt-7">
						{totalBanners.map(({ type, value }) => (
							<div className="col-12 mt-2 mt-md-0 col-md-4" key={type}>
								<TotalBanner type={type} value={value} />
							</div>
						))}
					</div>

					<div className="row mt-6">
						<div className="col-12 col-lg-5">
							<TopProducts
								products={salesData.topSellingProducts}
								currency={salesData.currency}
							/>
						</div>

						<div className="col-12 mt-6 mt-lg-0 col-lg-7">
							<PeriodReport
								data={salesData.monthlySales}
								type={EPeriodType.Month}
								currency={salesData.currency}
								totalAmount={salesData.totalSales}
								totalCount={salesData.totalTransactions}
							/>
						</div>
					</div>

					<div className="row mt-6">
						<div className="col-12 col-lg-6">
							<PeriodReport
								data={salesData.weekdaySales}
								type={EPeriodType.Weekday}
								currency={salesData.currency}
								totalAmount={salesData.totalSales}
								totalCount={salesData.totalTransactions}
							/>
						</div>

						<div className="col-12 mt-6 mt-lg-0 col-lg-6">
							<PeriodReport
								data={salesData.daypartSales}
								type={EPeriodType.DayPart}
								currency={salesData.currency}
								totalAmount={salesData.totalSales}
								totalCount={salesData.totalTransactions}
							/>
						</div>
					</div>
				</>
			)}
		</>
	);
};
