import { FC, useEffect } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { Login, ForgotPassword } from "./components";
import { toAbsoluteUrl, WithChildren } from "../../../_metronic/helpers";
import { AppLogo } from "../../components/shared/AppLogo";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AuthLayout: FC<{ isRoute?: boolean } & WithChildren> = (props) => {
  useEffect(() => {
    document.body.style.backgroundImage = "none";
    return () => {};
  }, []);

  return (
    <div
      className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
      style={{
        backgroundImage: `url(${toAbsoluteUrl(
          "/media/illustrations/sketchy-1/14.png"
        )})`,
      }}
    >
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />

      <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-xl-20">
        <AppLogo />

        <div className="w-100 w-sm-500px bg-body rounded shadow-sm p-7 p-sm-10 p-xl-15 mx-auto mt-10">
          {props.isRoute ? <Outlet /> : props.children}
        </div>
      </div>
    </div>
  );
};

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout isRoute />}>
      <Route path="login" element={<Login />} />
      <Route path="forgot-password" element={<ForgotPassword />} />

      <Route path="*" element={<Navigate to="login" />} />
      <Route index element={<Navigate to="login" />} />
    </Route>
  </Routes>
);

export { AuthPage, AuthLayout };
