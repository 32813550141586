import { FC } from 'react'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../_metronic/helpers'
import { Preview } from '../../../components/shared'
import { IMediaData } from '../../../types'

type TProps = {
  header: IMediaData
  footer: IMediaData
}

export const PreviewModal: FC<TProps> = ({ header, footer }) => {
  const intl = useIntl()

  return (
    (
      <div className='modal fade' id='modal-banners-preview' aria-hidden='true'>
        <div className='modal-dialog mw-75'>
          <div className='modal-content'>
            <div className='modal-header pb-0 border-0 justify-content-center'>
              <h1>{intl.formatMessage({ id: 'DEFAULT_BANNERS.RECEIPT_PREVIEW' })}</h1>

              <div
                className='btn btn-sm btn-icon btn-active-color-primary position-absolute top-0 end-0'
                style={{ padding: '1.25rem 1.25rem 0 0' }}
                data-bs-dismiss='modal'
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>

            <div className='modal-body scroll-y mt-10'>
              <Preview header={header} footer={footer} />
            </div>
          </div>
        </div>
      </div>
    )
  )
}